.container {
    font-size: 0.9em;
    font-weight: 100;
    text-transform: capitalize;
    height: 90px;
    width: 186px;
    display: flex;
    margin-right: 7px;
}   

.container img {
    height: 45px;
}
.providerLogo {
    max-width: 15em
}

@media (max-width: 700px){
    .providerLogo{
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    .container {
        width: 132px;
        font-size: 0.8em;
        font-weight: 400;
    }
    .container img {
        height: 40px;
    }
}

@media (max-width: 400px) {
    .container {
        width: 45%;
        font-size: 0.8em;
    }
    .container img {
        height: 30px;
    }
}

@media (max-width: 300px){
    .container {
      width: 100%;
    }
  }
.container {
    background: var(--black);
    width: 355px;
    height: 223px;
    box-shadow: 3px 3px 5px var(--dark-grey);
    border-radius: 16px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.container.clickable:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.container.medium {
    width: 160px;
    height: 100px;
    padding: 7px;
    border-radius: 7px;
}

.container.small {
    border-radius: 3px;
    padding: 3.5px;
    width: 71px;
    height: 45px;
    border: 0.5px solid var(--white)
}

.container > div:first-child {
    display: flex;
    justify-content: space-between
}

.container.selected, .container.clickable:active {
    background-color: var(--light-primary);
}

.container.selected .name, .container.clickable:active .name {
    color: var(--black)
}

.logo {
    height: 50px;
    width: auto;
    position: absolute;
    top: 18px;
    right: 18px;
}

.container.medium .logo {
    top: 8px;
    right: 8px;
    height: 23px;
}

.container.small .logo {
    top: 3.5px;
    right: 3.5px;
    height: 10px;
}

.balance {
    margin-top: 50px;
    font-size: 50px;
    color: #8ccb83;
    text-shadow: 1px 1px #fffde0;
}

.balance.negative {
    color: #ff5151;
}

.container.medium .balance {
    margin-top: 23px;
    text-shadow: unset;
    font-size: 23px;
}

.container.small .balance {
    margin-top: 10px;
    text-shadow: unset;
    font-size: 11px;
}
.balanceBadge {
    margin: 0.5em 0;
    border: 1px outset var(--light-grey);
    border-radius: 8px;
    text-align: center;
    background-color: var(--white);
    font-family: monospace;
    padding: 0.5em;
    /* box-shadow: ; */
    font-weight: 600;
    font-size: 15px;
}

.name {
    font-family: monospace;
    text-transform: uppercase;
    color: var(--primary);
}

.container.medium .name {
    font-size: 11px;
}

.container.small .name {
    text-shadow: unset;
    font-size: 5px;
}

@media (max-width: 400px) {
    .container {
        width: 284px;
        height: 179px;
        padding: 14px;
    }

    .container > div:first-child {
        max-width: 215px;
    }

    .balance {
        margin-top: 40px;
        font-size: 40px;
    }

    .logo {
        height: 40px;
        width: auto;
        top: 14px;
        right: 14px;
    }

    .name {
        font-size: 11px;
    } 

    .balanceBadge {
        font-size: 12px;
    }

}
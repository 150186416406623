.container {
    min-width: 300px;
    height: fit-content;
} 

.thumbTitle {
    text-align: center;
    margin: 0.25em;
}

.thumbImage {
    position: relative;
    width: 300px;
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: var(--black);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-style: outset;
    border-bottom: none;
    overflow: hidden;
}

.container :global(.upload svg){
    height: 100px
}

.thumbnailInner {
    display: flex;
}
.iframe {
    border: none;
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--translucent);
}

.overlay:hover {
   opacity: 1;   
}

.overlay svg {
    color: var(--white);
    opacity: 0;
    font-size: 70px;
    transition: opacity 1s;
}

.overlay:hover svg {
    opacity: 1;
 }

.modal {
    width: 50%;
    min-width: 400px;
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;
}

.socialBar {
    display: flex;
    align-items: center;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-style: outset;
    border-top: none;
    margin-top: -5px;
    padding: 5px;
}

.socialStats {
    display: flex;
    flex: 1;
}

.clickForFullscreen {
    background-color: var(--dark-translucent);
    position: absolute;
    display: flex;
    color: var(--white);
    bottom: 0.5em;
    right: 0.5em;
    padding: 5px;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid var(--white);
}

.clickForFullscreen svg {
    animation: grow 4s linear 0s infinite;

}
@keyframes grow {
    0% {
        transform: scale(0.5);
    }
    25% {
        transform: scale(1.25);
    }
}
.container {
    display: flex;
    padding: 1em;
}

.selectContainer {
    margin-right: 0.5em
}

.selectContainer input {
    background-color: var(--very-light-grey);
    border-radius: 8px;
    padding: 0.5em;
    border: 1px outset var(--light-grey)
}

.label {
    font-weight: 600;
}
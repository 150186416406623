.container {
    width: fit-content; 
    display: inline-block;  
}

.container span {
    margin: 0 5px 5px 0;
    background-color: var(--dark);
    color: var(--white);
    padding: 3px 10px;
    border-radius: 4px;
    display: inline-block;
    text-transform: capitalize;
    width: fit-content;
    white-space: nowrap;
    font-size: 0.8em;
    font-weight: 600;
}
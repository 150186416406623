.modal{
    height: 100%;
    margin-top: 100vh;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none;
    transition: margin-top 1000ms;
    padding-bottom:60px;
    background: var(--white)
}

.modal.slide {
    margin-top: 0;
}
.modalOverlay {
    padding: 5% 1% 0% 1%;
    z-index: 190;
}

@media(max-width: 700px){
    .modalOverlay {
        padding: 25% 1% 0% 1%;
    }
}

@media(max-height: 400px){
    .modalOverlay {
        padding: 5% 1% 0% 1%;
    }
}
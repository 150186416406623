.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.iconContainer {
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
}

.iconContainer.border {
    border: 1px solid var(--grey)
}

.iconContainer.shadow { 
    box-shadow: 0px 0px 5px 3px var(--light-grey);
}
.iconContainer > svg {
    margin-right: 0;
    font-size: 1.3em;
    color: var(--grey);
}

.iconContainer.backgroundColor > svg {
    color: var(--white)
}

.iconContainer:hover {
    background: var(--light-grey);
}

.iconContainer.iconContainer.backgroundColor:hover {
    filter: brightness(90%);
}

.label {
    font-size: 12px;
    text-transform: capitalize;
    color: var(--grey);
    margin-top: -3px;
}

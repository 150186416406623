.container {
    display: flex;
    justify-content: space-around;
    border: 1px outset;
    background: var(--white)
}

.stat {
    padding: 5px
}

.label {
    text-transform: uppercase;
    font-size: 0.8em;
    display: flex;
    align-items: center;
}

.label > svg {
    font-size: 20px;
    margin-right: 5px
}
.value {
    font-family: monospace;
    font-weight: 600;
    font-size: 2rem
}

@media (max-width: 700px) {
    .label { font-size: 0.5rem;}
    .value {font-size: 1.5rem;}
}
.container {
    display: flex;
    margin-top: 0.5em;
    width: 100%;
}

.userContainer {
    display: flex;
}

.userName {
    font-weight: 600;
    margin-left: 0.5em;
    text-transform: capitalize;
}
.container {
    background: var(--secondary);
    display: flex;
    padding: 1em;
    justify-content: space-between;
    margin-bottom: 1em;
} 

.iframe {
    border: none;
    width:420px;
    height: 315px;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em 1em 1em 10em;
    justify-content: space-between;
}

.title {
    color: white;
    font-size: 3em;
    font-family: Merienda, cursive;
    text-align: center;
}

.button {
    border: 1px solid var(--white);
    background: transparent;
    color: var(--white);
    padding: 0.5em 1em;
    font-size: 1.1em;
    width: 100%;
    text-align: center;
    cursor: pointer;
    
}

.button, button:visited, .button:active {
    text-decoration: none
}

@media(max-width: 1000px){
    .container {
        flex-direction: column
    }
    .content {
        padding: 1em;
        justify-content: space-between;
    }
    .title {
        font-size: 2em;
    }
    .button {
        font-size: var(--small-font)
    }
    .iframe{
        width: 100%;
        height: 20%;
        max-height: 50%;
    }
}
.container {
    background-color: var(--light-info-blue);
    padding: 1em 2em;
    text-align: center;
}

.container span {
    background-color: var(--info-blue);
    color: var(--white);
    padding: 5px;
    border-radius: 16px;
    margin-bottom: 2px;
    display: inline-block;
}

.container > div:first-child{
    padding-bottom: 1em;
}

@media(max-width: 700px){
    .container {
        font-size: 0.8em;
    }
}
.container {
    background: var(--primary-gradient);
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    max-width: 100%;
    height: 300px;
    overflow-x: auto;
} 

.elements {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1
}

.elementCircle {
    max-height: 120px;
    max-width: 120px;
    height: 12vw;
        width: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.element {
    color: var(--white);
    font-family: 'Raleway';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.elementText {
    margin-top: 5px;

}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
}

.title {
    color: white;
    font-size: 40px;
    font-family: 'Raleway';
    text-align: center;
}

@media(max-width: 1200px){
    .container {
        /* height: 200px;
        max-height: 200px */
    }

    .element {
        /* font-size: 0.8em; */
    }

    .elementCircle{
        height: 8vw;
        width: 8vw;
        border-radius: 12vw;
        min-width: 70px;
        min-height: 70px;
    }
    .content {
        padding: 1em;
        justify-content: space-between;
    }
    .title {
        font-size: 1.75em;
    }

}

@media(max-width: 900px){
    .element {
        /* font-size: 0.6em; */
    }
}
@media(max-width: 750px){
    .container {
        height: fit-content;
        padding: 1em;
    }
    .elements {
        /* max-height: 150px */
        flex-direction: column
    }
    .element {
        margin-bottom: 1em;
        /* font-size: 0.8em; */
    }
    .title {
        font-size: 1.4em
    }

}

@media(max-width: 350px){
    .title{
      /* top: 0;  font-size: 0.8em */
    }
}

@media(max-width: 300px){

}
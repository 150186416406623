.container {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border: 1px solid var(--light-grey);
    border-radius: 16px;
    margin-bottom: 1em;
}

.container > div{
    width: 370px;
}

.productTitle {
    margin-top: 0
}

.frame {
    display: flex;
    align-items: center;
    justify-content: center;
}
.productImage {
    cursor: pointer;
}

.price {
    font-weight: 600;
    font-size: var(--strong-font);
}

.sale .price{
    color: var(--sale-red)
}
@media (max-width: 1100px){
    .container > div {
        width: 50%;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .container > .frame {
        align-items: center;
        justify-content: center;
    }

    .container img {
        width: 100%;
        max-height: none;
    }
    
}

@media (max-width: 1100px){
    .productImage {
        max-height: 150px!important;
        width: auto !important;
    }
}

@media (max-width: 700px){
    .productImage {
        max-width: 120px!important;
        height: auto !important;
    }
}
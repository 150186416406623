.container {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 90%;
    margin-left: 5%;
    z-index: 101;
    border: 1px solid var( --dark-primary);
    font-weight: 500;
    padding: 0.5em;
    top: 15%
}

.container img {
    height: 4em;
    border: 1px solid var(--dark-primary);
    border-radius: 5px;
    margin-right: 1em;
}

.checkoutButton {
    margin-left: 10px;
}

.details {
    display: flex;
    align-items: center
}

@media(max-width: 1000px){
    .container{
        flex-direction: column;
    }

    .description {
        margin-bottom: 10px;
    }
}

@media(max-width: 700px){
    .container{
        top: unset;
        bottom: 40px;
    }

    .container img {
        margin-bottom: 0.5em
    }
}
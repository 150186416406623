.container {
    background: var(--primary-gradient);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    max-width: 100%;
    height: 350px;
    padding-right: 3em;
} 

.visual {
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    overflow: hidden;
}

.visual > img {
    height: 80%;
    width: auto;
    padding: 0 1em;
    max-height: 250px;
}

.phone {
    align-items: flex-end
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    justify-content: space-between;
}

.title {
    color: white;
    font-size: 40px;
    font-family: 'Raleway';
    text-align: center;
}

.small {
    display: none
}

.cost {
    color: var(--primary);
}
@media(max-width: 1000px){
    .content {
        justify-content: space-between;
    }
    .title {
        font-size: 1.75em;
    }

    .visual{
        height: 100%;
        max-height: 300px;
    } 
}

@media(max-width: 750px){

    .big {
        display: none
    }

    .small {
        display: block
    }
    .visual img {
        max-width: 70%
    }
    
    .visual {
        max-width: 100%;
        padding: 1em;
    }
    .container {
        /* max-height: 150px; */
        flex-direction: column;
        height: fit-content;
        flex-flow: column-reverse;
        justify-content: center;
        padding-right: unset;
    }
    .title {
        font-size: 1.5em
    }

}

@media(max-width: 350px){
    .title{
        font-size: 0.8em
    }
}

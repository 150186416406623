.container {
    font-size: 0.9em;
    font-weight: 100;
    text-transform: capitalize;
    min-height: 200px;
    width: 200px;
    justify-content: center;
    margin-right: 7px;
    cursor: pointer;
}   

:global(.unavailable) {
    cursor: not-allowed;
}
.innerContainer {
    height: 100%;
    width: 100%;
}
.deliveryName {
    font-weight: 600;
}

.deliveryLogo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 80px;
}

.fee {
    font-family: monospace;
    white-space: nowrap;
    padding: 0.5em 0;
    font-size: 14px;
}

.fee .explanation {
    font-size: 12px;
}

.fee .explanation span {
    font-weight: 600;
}
.getIt {
    font-size: var(--small-font);
    font-weight: 600;
    margin: 5px 0;
}

.getIt > div:first-child {
    margin-bottom: 2px;
}
.additionalInfo {
    font-size: var(--small-font);
    color: var(--info-blue);
    font-weight: 500;
}

.additionalInfo.deliveryInfo {
    color: var(--dark-primary)
}
.additionalInfo.error {
    color: var(--error-red)
}

.sameDay {
    color: var(--dark-primary);
    font-weight: 700
}

.pickupAddressContainer select {
    max-width: 200px;
    padding: 0.25em;
    border-radius: 8px;
    border: 1px outset;
    background-color: var(--white);
}

.pickupAddressContainer.noPickupChoice select{
    animation: pulse 1.5s ease 0s infinite;
}   
@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0px 0px var(--dark-warning-yellow);
    }
    100% {
      box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0);
    }
}

@media (max-width: 700px){
    .container{
        width: 150px;
        min-width: 150px;
        height: unset;
        min-height: unset;
    }

    .fee {
        white-space: normal;
    }

    .fee .explanation {
        font-size: 10px;
    }
    .fee .explanation > div {
        margin-bottom: 5px;
    }

    .fee .explanation span {
        display: block;
    }
}

@media (max-width: 600px){
    .pickupAddressContainer select {
        max-width: 40vw;
    }
    
}

@media (max-width: 450px){
    .container{
        width: 140px;
        min-width: 135px;
        font-size: 0.75em;
        margin-right: 3px;
        overflow: hidden;
    }
}

@media (max-width: 380px){
    .container{
        width: 100%;
    }
}
@media (max-height: 300px){  
    .pickupAddressContainer select {
        max-width: 20vw;
    }
}
.container {
    position: fixed;
    bottom: 10%;
    right: 30px;
    background: var(--black);
    padding: 0.5em 1.5em;
    z-index: 190;
    border: 1px outset;
    border-radius: 50px;
    color: var( --white);
    font-family: 'Raleway';
    display: flex;
    align-items: center;
    cursor: pointer;
}

.container:hover {
    filter: brightness(110%);
}

.name{
    color: var(--sale-red);
    font-weight: 600;
}

.link{
    color: var(--white);
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-size: var(--small-font);
} 

.link > svg {
    font-size: 1em;
}

.link:hover {
    font-weight: 600;
}
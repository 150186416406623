.container {
    width: 700px;
    border-radius: 16px;
    margin: 0 1em 1em 0;
}

.checkoutButton {
    padding: 1em 0;
    display: flex;
    justify-content: center;
}

.disabledButtonContainer {
    text-align: center;
}
.instructionsList {
    font-size: var(--small-font);
    text-align: left;
    color: var(--error-red);
    line-height: 100%;
}
@media(max-width: 1270px){
    .container{
        width: 100%;
        margin-right: 0;
        margin-bottom: 1em;
    }
}
.modal {
    height: fit-content;
}
.modalInner { 
    padding: 0.5em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.instructions {
    max-width: 300px;
}

.upload {
    width: 200px;
    margin-top: 5px
}
.container {
    display: flex;
    height: 100%;
}

.productImage{
    max-height: 100%;
    max-width: 100%;
}

.imageFrame {
    height: 100%;
    width: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px){
    .imageFrame {
        min-width: 0%;
        width: 100%;
        margin-right: 0;
    }
}

.thumbnailList{
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
}

.thumbnailList > div {
    margin-bottom: 0.5em
}

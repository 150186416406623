
.itemList {
    background-color: var(--very-light-grey);
}

.iconBack {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: var(--light-grey);
}
.title {
    /* padding: 1em;
    font-size: 1.3em; */
}
.container {
    text-align: center;
    flex: 1;
    cursor: pointer;
    border-radius: 50px;
    -webkit-tap-highlight-color: transparent;
}
.tile{
    flex: 1;
    text-align: center;
    color: var(--white);
}
.container:active, .container.selected, .container.selected:hover {
    color: var(--primary);
    flex: 1;
    text-align: center;
}

.container.selected:hover > svg{
    color: var(--primary);
}

.container > svg {
    margin-bottom: -8px;
} 

.container:hover, .container:hover > svg{
    color: var(--light-primary);
}

.container > div {
    font-size: var(--small-font);
    font-weight: 400
}

@media (max-width: 300px){
    .container > div {
        font-size: var(--tiny-font);
    }
}
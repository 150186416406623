.modal{
    height: 100vh;
    margin-left: 120vw;
    width: 100%;
    outline: none;
    transition: margin-left 500ms;
    padding: 0;
    background: var(--green-grey);
    overflow: hidden;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.modal.slide {
    margin-left: 0;
}
.modalOverlay {
    padding: 0% 0% 0% 60%;
    z-index: 201;
}

@media(max-width: 700px){
    .modalOverlay {
        padding: 0% 0% 0% 20%;
    }
}

.loginOrCreate {
    padding: 0 1em 2em 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.loginOrCreate button {
    display: block;
    width: 100%;
}

.loginOrCreate > div {
    margin: 0.5em;
    text-transform: uppercase;
}

.header {
    background: var(--white);
    padding: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px outset;
    margin-bottom: 2em;
}
.logo {
    height: 50px;
    user-select: none;
}

.greeting {
    color: var(--black);
    margin-left: 1em;
    text-transform: capitalize;
    border: 1px solid var(--light-grey);
    border-radius: 30px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    margin-right: 1em;
    cursor: pointer;
    background-color: var(--white);
}

.greeting span {
    margin-left: 5px
}

.greeting:hover {
    filter: brightness(90%)
} 

.userDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5em;
}

.name {
    font-weight: 600;
}
.container {
}

.optionList {
    display: flex;
    max-width: 100%;
    overflow: auto;
    padding: 5px;
    position: relative;
}

.option {
    border: 1px outset;
    border-radius: 8px;
    font-size: 13px;
    padding: 3px 5px;
    margin-right: 5px;
    text-transform: capitalize;
    white-space: nowrap;
    background-color: var(--white);
}

.option.selected {
    border: 1px solid var(--black);
    background-color: var(--light-primary);
    font-weight: 700;
}
.option:hover {
    background-color: var(--light-primary);
    box-shadow: 0px 0px 2px 1px var(--grey);
}
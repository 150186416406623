.container {
    background: var(--primary-gradient);
    display: flex;
    margin-bottom: 1em;
    max-width: 100%;
    height: fit-content;
    max-height: 400px;
    overflow:hidden;
    color: var(--white);
}

.right {
    flex: 1;
}

.right {
    display: flex;
    padding:2em;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}
.container img {
    width: 100%;
    height: auto;
}

.heading {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
}

.explanation {
    margin: 1em;
    text-align: justify;
}
@media(max-width: 1350px){
    .container img {
        width: auto;
        height: 100%;
    }
    .left {
        min-height: 235px;
        /* width: 50%; */
        overflow: hidden;
    }
}
@media(max-width: 1200px){

    .container iframe {
        width: 100%;
        /* height: auto; */
    }
    .left {
        height: unset;
        width: 100%;
        min-height: unset;
    }
    .explanation {
        font-size: 0.9em;
        margin: 1em;
    }
    .heading {
        font-size: 1.75em
    }
    .container{
        height: fit-content;
        flex-flow: column-reverse;
        flex-direction: column;
        max-height: unset;
    }
}
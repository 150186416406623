.container {
    height: 100vh;
    background: var(--translucent);
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 210;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container.contained {
    position: absolute;
    height: 100%;
    width: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.container {
    display: flex;
    background: var(--white);
    padding: 5px 1em 0 1em;
    align-items: center;
    min-height: 80px;
    height: fit-content;
}

.title {
    text-transform: capitalize;
}

.icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--light-grey);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transactionDetails {
    border-bottom: 0.5px solid var(--light-grey);
    flex: 1;
    display: flex;
    flex-direction: column;
    height: fit-content
}

.transactionDetails > div:first-child {
    font-weight: 600
}

.reason {
    font-size: var(--small-font);
}

.endingBalance{
    font-size: 0.7em;
    margin-bottom: 5px;
    color: var(--grey)
}

.endingBalance .negative {
    color: var(--sale-red)
}
.credit {
    color: var(--credit-green)
}

.balance {
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px
}
.container {
    font-size: 0.9em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.container > div {
    margin-right: 0.5em
}

.container span {
    font-weight: 600;
}

@media(max-width: 700px){
    .container {
        font-size: 0.7em;
    }
}
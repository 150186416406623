.categoryContent {
    flex: 1;
    max-width: 85vw;
}

@media (max-width: 1000px){
    .categoryContent{
        max-width: 75vw
    }
}

@media (max-width: 700px) {
    .categoryContent {
        max-width: 100vw;
    }
}
.container {
    display: flex;
    border: 1px outset var(--light-grey);
    padding: 0.5em;
    border-radius: 16px;
    min-width: 220px;
    width: 220px;
    position: relative
}

.description {
    margin: 0 20px 0 0.5em;
    flex: 1;
    font-size: var(--small-font);
}

.characteristics {
    font-size: 10px;
    max-height: 50px;
    max-width: 100%;
    overflow: auto
}

.title {
    font-weight: 500;
    max-height: 40px;
    width: 100%;
    overflow-y: auto;
}

.close {
    width: 37px;
    right: 2px;
    top: 2px;
    position: absolute;
}
 
.container{
    margin: 2px 0;
    padding: 8px;
    border-radius: 16px;
}

.container:hover {
    border: 1px outset var(--light-grey);
    cursor: pointer;
}

.container.checked {
    background-color: var(--light-primary);
}
.container.replaced {
    background-color: var(--warning-yellow);
}

.container.actual {
    background-color: var(--light-info-blue);
}

.container.refund {
    background-color: var(--light-info-pink);
}

.itemCheck, .itemCheckbox {
    display: flex;
    align-items: center;
}

.itemCheck {
    justify-content: space-between;
}

.listItemText {
    margin-right: 0.5em;
    display: flex;
    align-items: center;
}

.listItemText .text {
    padding-left: 0.5em
}

.itemEffect > span {
    font-weight: 600;
    margin-left: 5px;
    border: 1px outset var(--grey);
    border-radius: 16px;
    padding: 1px 5px;
    color: var(--white);
    font-size: var(--small-font);
}

.refund .itemEffect > span {
    background-color: var(--info-pink);
}

.replaced .itemEffect > span{
    background-color: var(--dark-warning-yellow);
}
.actual .itemEffect > span{
    background-color: var(--info-blue);
}

.sku {
    font-weight: 600;
}

@media (max-width: 700px) {
    .listItemText {
        display: inline-block;
    }
}
.container {
    width: 100%;
    background: linear-gradient(to bottom right, #18fbf4, black);
    /* background: ; */
    /* color: var(--white); */
    font-family: 'Raleway';
    padding: 1em;
}

.eventDetails, .off, .sellerContainer {
    color: white
}

.earlyBird {
    background-image: url("/early-bird200x200.png");
    background-size: contain;
    height: 150px;
    width: 150px;
    position: absolute;
    animation: slideAnimation 5s forwards;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(30vw);
  }
  100% {
    transform: translateX(100vw);
    display: none;
  }
}
.homeLink {
    color: var(--black);
    width: 100%;
}
.hero {
    font-size: 6em;
    margin-bottom: 1em;
    text-align: center;
    font-family: fantasy, sans-serif;
    color: #ddbc74;
    text-shadow: 2px 2px 8px black
}

.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
}
.startsIn {
    font-size: 2em;
    width: 50%;
}

.timer {
    margin-bottom: 1em;
}
.hero > span {
    color: var(--sale-red)
}

.notifyButton {
    background-color: var(--white);
    color: var(--black);
    font-weight: 400!important;
    display: flex;
    align-items: center;
}

.notifyButton > svg, .subscribed > svg {
    font-size: 1.5em;
}

.notifyButton:hover {
    background-color: black;
    color: var(--white);
}

.notifyButton:active {
    border: 3px solid red;
}

.sellerContainer {
    padding: 1em 0;
    width: 100%;
}
.sellers {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sellers > div {
    margin: 0.5em;
}



.sellerIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sellerIcon:hover {
    cursor: pointer;
    filter: brightness(80%);
}

.sellerName {
    font-size: var(--small-font);
    max-width: 80px;
    overflow-wrap: break-word;
    text-align: center;
    text-transform: capitalize;
    
}

.subscribed {
    min-height: 3em;
    font-size: 1.2em;
    font-weight: 400;
    border-radius: 4em;
    display: flex;
    align-items: center;
    border: 2px solid red;
    padding: 2px 1em;
}

.seeMore {
    background: var(--white);
    margin-top: 2em;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0.5em;
    color: var(--black);
    border: 1px outset var(--light-grey);
    border-radius: 40px;
    cursor: pointer;
}

.seeSeller {
    display: flex;
    align-items: center;

}
.seeMoreName {
    margin-left: 5px;
    margin-right: 1em;
}
.seeMore:hover {
    filter: brightness(90%)
}
.seeAll {
    margin-left: 2em;
    display: flex;
    align-items: center;
}

.off {
    margin-top: 1em;
    text-align: center;
}

.timing {
    font-size: var(--small-font);
    text-align: center;
}
@media(max-width: 700px){
    .hero {
        margin-bottom: 20px;
        font-size: 4.5em;
    }
    .startsIn {
        font-size: 1.5em;
        width: 100%;
    }
}

@media(max-width: 700px){
    .earlyBird {
        height: 80px;
        width: 80px;
        top: 300px
    }
}
@media(max-width: 400px){
    .container{
        padding-top: 0;
    }
    .hero{
        font-size: 3.5em;
        padding-top: 0;
        /* margin-top: -80px; */
    }

    .sellerContainer {
        padding-top: 0.5em;

    }

    
}

.cartItemList {
    max-height: 60vh;
    height: auto;
    overflow: auto;
}
.buttonHolder {
    display: flex;
    justify-content: flex-end;
    padding: 0.5em;
}

.buttonHolder > button {
    margin-left: 1em;
}

.modal {
    height: fit-content;
}

.title {
    padding: 1em;
    font-size: 1.3em;
}
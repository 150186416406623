.container {
    background: var(--white);
    border: 1px outset;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px var(--grey);
    position: fixed;
    bottom: -300px;
    transition: bottom 800ms;
    left: 1em;
    padding: 0.5em 1em;
    z-index: 202;
    display: flex;
    min-height: 160px;
    max-height: 210px;
    width: 360px;
    cursor: pointer;
    user-select: none;
}
.container:hover {
    filter: brightness(90%)
}

.popup {
    bottom: 1em
}

.iconContainer {
    flex: 1
}

.iconContainer > img {
    width: 80px;
    height: 80px;
}

.contentContainer{
    width: 200px;
    overflow-x: hidden;
}

.title {
    font-size: 1em;
}
.body {
    font-size: var(--small-font);
    margin: 5px 0;
}
@media(max-width: 400px) {
    .container {
        display: none;
    }
}
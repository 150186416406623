.container {
    padding: 0.5em;
    background-color: var(--green-grey);
    min-height: 100%;
}

.prompt {
    text-align: center;
    margin-bottom: 0.5em;
}

.title{
    margin-bottom: 0.5em;
}

.amount {
    display: flex;
    justify-content: center;
}
.info {
    display: flex;
    align-items: center;
}
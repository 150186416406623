.container {
    text-transform: capitalize;
    background: var(--warning-yellow);
    padding: 1px 10px;
    white-space: nowrap;
    font-weight: 600;
}

.container.today {
    color: var(--dark-primary);
    background-color: var(--light-primary);
}

.container.tomorrow {
    color: var(--info-blue);
    background: var(--light-info-blue);
}
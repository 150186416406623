.container{
    
}

.tableTop {
    display: flex;
    justify-content: space-between;
    margin : 3px 16px 8px 6px;
}
.tableContainer {
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 16px;
    border: 1px outset var(--light-grey);
    box-shadow: 2px 2px 5px 0px var(--grey);
}

.tableContainer.noScroll {
    overflow-y: unset;
}
.table {
    border-collapse: collapse;
    width: 100%;
    background-color: var(--white)
}

.noData{
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--title-font);
    padding: 1em;
    background: white;
}

.clickable {
    cursor: pointer;
}

.search {
    display: flex;
    align-items: center;
}

.search > div {
    margin-right: 5px;
    margin-bottom: 0;
    border-radius: 5px;
    background: var(--white);
    height: 32px;
}

.search > select {
    margin: 0;
    padding: 0.5em;
    border-radius: 4px;
}
.search input {
    border-color: var(--grey)
}

@media(max-width: 700px){
    .container {
        font-size: var(--small-font)
    }

    .noData{
        font-size: var(--large-font-phone)
    }
}

.headerContainer {
    background-image: var(--primary-gradient);
    position: sticky; 
    top: 0;
    z-index: 100;
}
.th {
    color: var(--white);
    padding-left: 15px!important;
    padding-right: 15px!important;
}

.th > div {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    color: var(--dark);
    font-size: 1.2em
}

/* row styles*/

.rowContainer {
    vertical-align: top;
    border-bottom:5px solid var(--very-light-grey);
    border-spacing: 1em;
    font-size: 0.95em;
    background-color: var(--white);
}


.rowContainer.even {
    background-color: #f6f4f4
}

.rowContainer:hover, .rowContainer.even:hover, .selected, .rowContainer.even.selected {
    filter: brightness(85%)
}

.rowContainer.error {
    background-color: var(--translucent-accent)
}

/* cell styles*/
.cellContainer {
    text-align: center;
    padding: 0.5em;
}
.container {
    padding: 10px;
    border: 1px outset var(--light-grey);
    border-radius: 16px;
    margin-bottom: 7px;
    background-color: var(--white);
}

.innerContainer {
    display: flex;
}

.sellerLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
}

.deliveryDetails {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.description {
    flex: 1;
    padding-right: 0.5em;
}

.description > div {
    margin-bottom: 5px;
}

.deliveryTime {
    font-size: 1.2em;
    font-weight: 700;
}
.deliverAt {
    font-weight: 600;
}

.deliverAt img {
    vertical-align: middle;
}
.deliveredBy {
    font-size: 0.9em;
}

@media(max-width: 700px){
    .innerContainer {
        flex-direction: column;
        align-items: center;
    }
    .deliveryDetails {
        display: block;
    }
    .right {
        margin-top: 5px;
        text-align: right;
    }
    .sellerLogos {
        margin-bottom: 0.5em;
    }
}

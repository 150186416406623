.container > span {
    margin-right: 10px
}
.container > select{
    padding: 3px 5px;
    border-radius: 3px;
}

.min {
    margin: 1em 0;
    font-size: var(--small-font);
}

.container select:hover {
    cursor: pointer;
}
.topBarContent {
    flex: 1;
    text-align: center;
    color: var(--white);
    font-size: var(--title-font);
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topBarContent > div {
    margin-right: 0.5em;
}
@media(max-width: 700px){
    .container {
        height: fit-content;
        padding: 5px 0px;
    }
}
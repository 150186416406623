.container {
    color: var(--dark);
    display: flex;
    padding: 2px 15px;
    border-radius: 37px;
    align-items: center;
    justify-content: space-between;
    min-width: 110px;
    max-width: 135px;
    cursor: pointer;
    background-color: var(--white);
}

.container:hover{
    filter: brightness(90%);
}
.container > svg {
    font-size: 20px;
    margin-right: 5px;
}
.balance {
    font-size: 0.8em;
    font-weight: 600;
    white-space: nowrap;
}
.items {
    font-size: 0.7em;
}

.items > span {
    font-weight: 700
}

.container:active {
    border-style: inset
}
.container {
    background: var(--secondary);
    color: var(--white);
    padding: 1em 0 1em 1em;
}
.container a {
    color: var(--white);
}
.bottom {
    display: flex;
    justify-content: space-between;
}

.bottom img {
    height: 25px;
    width: auto
}

.spaced {
    margin-bottom: 0.5em
}
.blocks {
    display: flex;
    margin-bottom: 1em;
    flex-wrap: wrap;
}
.stack {
    padding: 2em 1em;
    display: flex;
    flex: 1;
    flex-direction: column;
    /* width: 300px; */
    margin: 0 1em 1em 0;
    background: #ffffff2e;
    border-radius: 0px;
}

.stack > div {
    margin-bottom: 5px;
}

.visaMastercard img {
    height: 48px;
    width: auto;
    margin-right: 5px
}
@media (max-width: 400px){
    .container {
        font-size: var(--small-font)
    }
}
@media(max-width: 700px){
    .stack {
        width: 100%
    }
}
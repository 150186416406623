.container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--white);

    padding: 0.2em;

    text-transform: capitalize;
    font-size: 0.9em;

    margin-bottom: 5px;
    border-radius: 30px;
    border: 1px outset;
}

.container span {
    margin-left: 5px
}

.container:hover {
    filter: brightness(90%)
}
.container {
    position: fixed;
    bottom: 0;
    z-index: 102;
    right: 5%;
    text-align: right;
}

.innerContainer {
    height: 0px;
    transition: height 1000ms;
    overflow: hidden;
    background: var(--light-grey);
    border: 1px outset;
    border-radius: 5px;
    border-top-right-radius: 0;
}

.innerContainer.show {
    height: 300px;
}

.closeButtonContainer {
    text-align: right;
    padding: 5px;
}

.prompt {
    padding: 5px 10px;
    display: inline-block;
    background: var(--primary);
    border: 1px outset var(--white);
    border-bottom: none;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media (max-width: 400px){
    .container{ 
        display: none;
    }
}
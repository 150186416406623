.videoFrame, .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em;
}

.instructionsToggle {
    display: flex;
    margin: 0.5em;
}

.instructionsToggle > div {
    padding: 0.5em;
    border: 1px outset;
    background: var(--white);
    cursor: pointer;
    display: flex;
    font-size: 0.9em;
    font-weight: 600;
}

.instructionsToggle > div > span{
    display: flex;
    align-items: center;
}

.instructionsToggle > div.selected {
    border-style: inset;
    background-color: var(--light-primary);
}

.instructionsToggle > div > span:first-child{
    margin-right: 5px;
}
.instructionsToggle > div:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.instructionsToggle > div:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.instructionsToggle > div:hover {
    filter: brightness(90%)
}

.note {
    font-weight: 600;
}

.statusRow {
    display: flex;
    padding: 1em;
    border: 1px outset;
    align-items: center;
    background: var(--very-light-grey);
    border-radius: 16px;
}

.orderHeader {
    margin-bottom: 0.5em
}
.orderTitle {
    font-weight: 300;
    font-size: var(--title-font)
}
.circle {
    height: 2vw;
    width: 9vw;
    border-radius: 9vw;
    background: var(--grey);
    border: 2px solid var(--dark);
    max-width: 100px;
    max-height: 100px;
}

.circle.completed {
    background: var(--primary);
    border: 2px solid var(--dark-primary)
}

.circle.current {
    animation: pulse 2s ease 0s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
    100% {
      box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0);
    }
  }
  
.path {
    background: var(--dark);
    flex: 1;
    height: 2px;
}

.path.completed {
    background: var(--dark-primary);
}

.sellerName {
    width: 9vw;
    text-transform: capitalize;
    font-weight: 600;
    margin-right: 5px;
    border-right: 0.5px solid var(--grey);
    margin-right: 0.5em;
    padding-right: 0.5em;
}
.stages {
    display: flex;
    flex: 1;
}

.stage {
    flex: 1;
}

.startStage {
    flex: none
}

.stageLabel {
    display: flex;
    justify-content: flex-end;
}
.stageLabel > div {
    width: 9vw;
    text-align: center;
    font-size: var(--small-font);
    max-width: 100px;
}

.stageLabel .label {
    font-weight: 600;
    margin-bottom: 5px;
}
.stageVisual {
    display: flex;
    align-items: center;
    margin-bottom: 5px
}

.deliveryText {
    font-size: var(--small-font);   
    font-weight: 400;
    margin-top: 1em;
}

@media(max-width: 700px){
    .circle {
        width: 9vw;
        border-radius: 11vw;
    }
    .sellerName {
        font-size: var(--small-font);
        word-wrap: break-word;
        
        width: 17vw
    }

    .stageLabel > div {
        width: 9vw;
        font-size: var(--tiny-font);
        overflow-x:scroll
    }
}

@media(max-width: 400px){
    .stageLabel > div {
        font-size: 0.6em;
    }
}
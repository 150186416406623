.container {
    padding: 1em;
    padding-bottom: 10em;
    width: 100%;
    background: var(--green-grey);
}

.heading {
    text-transform: capitalize
}

.container > :global(.title), .container :global(.strong){
    padding: 0.5em 1em;
}

.categoryLinkContainer.selected {
    background: var(--primary);
}

.productContainer  {
    /* padding-top: 5em */
}

.categoryContainer {
    /* background: var(--light-primary);
    padding: 1em;
    border: 1px inset; */
}

.categoryLinkContainer {
    display: inline-flex;
    padding: 0.5em 1em;
    border: 1px solid var(--grey);
    border-radius: 20px;
    margin: 0.3em;
    cursor: pointer;
    background: var(--white);
    flex: 1;
    height: fit-content;
    white-space: nowrap;
}

.categoryLinkContainer:hover {
    filter: brightness(90%)
}
.link {
    text-transform: capitalize;
    color: black;
    text-decoration: none;
    font-size: var(--small-font);
    cursor: pointer;
    font-weight: 600
}

.returnLink{
    margin-left: 1em;
    display: flex;
    align-items: center;
    text-decoration: underline;
    padding: 1em;
}
.categoryTitle {
    display: flex;
    align-items: center
}

.arrow {
    display: none
}

.hotDeals {
    background-color: var(--warning-yellow);
}
@media(max-width: 700px){
    .container{
        padding: 0 0 10em 0;
    }

    .categoryContainer .categoryLinkContainer:last-child{
        padding-right: 2em;
    }
    .categoryContainer {
        overflow-y: hidden;
        display: flex;
        overflow-x:scroll;
        width: 100%;
        position: relative;
        scroll-behavior: smooth
    } 
    .arrow {
        position: sticky;
        top: 35%;
        height: 35px;
        padding: 0 6px;
        background: #00000069;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
        cursor: pointer;
        
    }
    .arrow:active, .arrow:focus {
        box-shadow: 0px 0px 8px 3px var(--primary);
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
    .left {
        left: 0px
    }
    .right {
        right: 0px
    }
}
:root {
  --white: #fff;
  --off-white: #f1f1f1;
  --black: #000;
  --dark: black;
  --primary: #7ddc70;
  --light-primary: #cdfbe8;
  --dark-primary: #208812;
  --secondary: #276354;
  --primary-gradient: linear-gradient(to right, var(--secondary), var(--primary));
  --grey: #7e7e7e;
  --light-grey: #e2e2e2;
  --very-light-grey: #f5f8fa;
  --very-light-grey-prev: #f5f5f5;
  --disabled-grey: #cfd3cf;
  --dashboard-grey:#f0f0f0;
  --inactive-grey: #bbb5b5;
  --placeholder-grey: rgb(175, 175, 175);
  --dark-grey: #504d4d;
  --green-grey: #ddedea;
  --nbd-green: #005737;
  --facebook-blue: #385898;
  --whatsapp-green: #25d366;
  --youtube-red: #ff0000;
  --nbd-label-green: #007700;
  --nbd-field-yellow: #FFFF99;
  --nbd-mobile-grey: #e0e0e0;
  --nbd-mobile-label-black: #030f0b;
  --warning-yellow: #feee94;
  --tooltip-yellow: #f8f6dd;
  --dark-warning-yellow: #df9824;
  --error-red: #e04747;
  --light-error-red: #ffb5b5;
  --very-light-error-red: #f8dbdb;
  --sale-red: red;
  --action-blue: #2873d0;
  --light-translucent:  rgb(0 0 0 / 39%);
  --translucent:  rgba(0, 0, 0, 0.67);
  --dark-translucent:  rgba(0, 0, 0, 0.8);
  --info-blue: #0440c0;
  --link-blue: #1a73e8;
  --light-info-blue: #bfd3ff;
  --info-pink: #d54cd5;
  --light-info-pink: #f5d6f5;
  --credit-green: #68cb5b;
  --searchBarHeight: 100px;
  --topBarHeight: 100px;
  --sideBarBottomHeight: 100px;
  --tiny-font: 0.7em;
  --small-font: 0.8em;
  --med-font: 0.9em;
  --strong-font: 1.2em;
  --title-font: 1.5em;
  --header-font: 2.5em;
}
@font-face {
  font-family: 'Merienda';
  src: local('Merienda'), url(../fonts/Merienda-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DancingScript';
  src: local('DancingScript'), url(../fonts/DancingScript.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@media (max-width: 700px){
  :root {
    --searchBarHeight: 165px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;  
}
html, body, #root {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

*, ::after, ::before {
  box-sizing: inherit
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bold {
  font-weight: 600;
}

.button {
  min-height: 32px;
  height: 32px;
  border-radius: 4em;
  background-color: var(--grey);
  color: var(--white);
  border-width: 0.5px;
  border-color: var(--very-light-grey);
  outline: none;
  padding: 2px 1em;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
}

a.button {
  text-decoration: none;
  display: inline-block;
  border: 2px outset;
}

.button:hover, .button:focus {
  filter: brightness(85%)
}

.button.dark {
  background-color: var(--dark)
}

.button.white {
  background-color: var(--white);
  border-color: var(--dark);
  color: var(--dark)
}

.button.primary{
  background: var(--primary);
  color: var(--dark);
  font-weight: 600;
}

.button.warning {
  color: var(--white);
  background: var(--dark-warning-yellow);
  border: 1px outset var(--dark-warning-yellow);
}

.button.error {
  background-color: var(--error-red)
}

.button.action {
  background-color: var(--action-blue)
}

.button.info {
  background-color: var(--info-blue)
}

.button.disabled {
  background: var(--disabled-grey);
  color: var(--grey);
  border-style: solid;
  border-color: var(--grey);
  font-weight: 600;
  cursor: not-allowed;
}
.button.disabled:active, .button.disabled:hover, .button.disabled:focus{
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey);
  outline: none;
  filter: brightness(100%)
}
.button.big {
  min-height: 3em;
  font-size: 1.2em;
  font-weight: 100;
  border-radius: 4em;
}

.button.wide {
  width: 100%
}
.button.outline {
  background: var(--white);
  border: 2px solid var(--dark-primary);
  color: var(--dark-primary);
  font-weight: 600;
}

.button.outline:hover{
  background: var(--light-primary);
  filter: brightness(100%)
}

.button.gradient {
  background-image: var(--primary-gradient);
}

.button.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.icon svg {
  margin-right: 5px;
}
.button.icon-right svg {
  margin-right: 0px;
  margin-left: 5px;
}
@media(max-width: 700px){
  .button {
    font-size: var(--small-font);
    white-space: normal;
    height: unset;
  }
}

@media(max-width: 300px){
  .button {
    font-size: var(--tiny-font)
  }
}

.buttonHolder {
  display: flex;
  justify-content: flex-end;
}

.buttonPanel {
  padding: 0.5em
}

.buttonHolder > button {
  margin-left: 0.5em
}
.input, .textarea, .select{
  padding: 12px;
  width: 100%;
  border: 0.5px solid var(--grey);
  background: var( --very-light-grey);
  border-radius: 4px;
}

::placeholder {
  color: var(--placeholder-grey);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--placeholder-grey)
}

.input.error {
  background-color: var(--light-error-red);
  border-color: var(--error-red);
}
.select {
  cursor: pointer;
}

.tableSelect {
  padding: 3px;
  border: 1px outset var(--light-grey);
  border-radius: 4px;
}

.textarea {
  min-height: 80px;
}

.radioList > label{
   margin: 0 0 0.5em 0
}

.radioList.horizontal {
  display: flex;
}

.radioList.horizontal > label {
  margin: 0 0.5em 0 0 
}

.readOnly {
  background: var(--light-grey);
  cursor: not-allowed;
}

.badge {
  padding: 1px 10px;
  color: var(--dark-grey);
  background: var(--light-grey);
  border-radius: 20px;
  border: 1px solid var(--dark-grey);
  display: inline-block;
  text-transform: capitalize;
  width: fit-content;
  white-space: nowrap;
  font-size: 14px;
}

.badge.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge.icon svg {
  font-size: 16px;
  margin-right: 5px;
}

.badge.primary {
  color: var(--dark-primary);
  background: var(--light-primary);
  border: 1px solid var(--dark-primary);
}

.badge.error {
  color: var(--error-red);
  background: var(--light-error-red);
  border: 1px solid var(--error-red);
  margin: 5px;
}

.badge.warning {
  color: var(--dark);
  background: var(--warning-yellow);
  border: 1px solid var(--dark-warning-yellow);
}

.badge.dark {
  color: var(--white);
  background: var(--dark);
  border: 1px solid var(--white);
}

.badge.outline {
  color: var(--dark);
  background: var(--white);
  border: 2px solid var(--dark);
}

.badge.info {
  color: var(--white);
  background: var(--info-blue);
  border: 1px solid var(--info-blue);
}

.badge.element {
  color: var(--white);
  background: var(--info-pink);
  border: 1px solid var(--info-pink);
}

.badge.wrap {
  white-space:normal;
}

.badgeHolder .badge{
  margin: 5px;
}
.screen {
  min-height: 100%;
  display: block;
  background: var(--green-grey)
}

.termsScreen{
  overflow-y: auto;
  background-color: var(--very-light-grey);
}
.searchBarPresent {
  padding-top: var(--searchBarHeight)
}

.topBarPresent {
  padding-top: var(--topBarHeight)
}

.mainContent {
  flex: 1;
  display: flex;
  min-height: 100%;
}

.logo {
  cursor: pointer;
}

@media (max-width: 700px){
  .mainContent{
    padding-bottom: var(--sideBarBottomHeight);
  }
}

.title {
  font-weight: 600;
  font-size: var(--title-font);
  font-family: 'Raleway';
  display: flex;
  align-items: center;
}

.title.center {
  justify-content: center;
}

.title > svg {
  margin-right: 0.5em;
}

.center {
  text-align: center;
}
.centerDiv {
  display:flex;
  align-items: center;
  justify-content: center;
}
.strong {
  font-weight: 600;
  font-size: var(--strong-font)
}

.space-bottom {
  margin-bottom: 1em;
}

.space-bottom-05em {
  margin-bottom: 0.5em
}

.space-top-05em {
  margin-top: 0.5em
}
.space-right {
  margin-right: 1em;
}
.space-right-05em {
  margin-right: 0.5em;
}
.space-left {
  margin-left: 1em;
}

.space-left-05em {
  margin-left: 0.5em;
}
.strong.icon {
  display: flex;
  align-items: center;
}

.strong.icon svg {
  font-size: 40px;
} 

.strongTitle {
  font-size: 1.5em;
}
.productTitle{
    font-size: var(--header-font);
    margin: 0.2em 0;
    font-weight: 100;
    color: var(--grey);
}

@media (max-width: 400px){
  .productTitle {
    margin-top: 1em;
    font-size: 2em;
  }

  .strongTitle {
    font-size: 1.2em;
  }
}
.productImage{
  max-height: 220px;
  max-width: 300px;
  border-radius: 8px;
}

.preload {
  width: 100%;
  height: 200px;
  min-height: 100px;
  max-height: 100%;
  max-width: 100%;
  background: var(--light-grey);
  border-radius: 4px;
}

.preload img {
  visibility: hidden;
}

.postload {
  background: none;
}

.postload > div {
  height: 100%;
}

.topBar{
    height: var(--searchBarHeight);
    background-image: var(--primary-gradient);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 101;
    top: 0;
}

.topBar img {
  margin-left: 1em;
  height: 90px;
  width: auto;
}

@media (max-width: 700px){
  .topBar img {
      height: 70px;
  }
}

@media (max-width: 400px){
  .topBar .logo {
      height: 70px;
  }
}

@media (max-height: 400px){
  .topBar {
    position: relative
  }
  .searchBarPresent {
    padding-top: 0
  }
  
  .topBarPresent {
    padding-top: 0
  }
}
.anchorLink {
  text-decoration: underline;
  color: var(--dark);
  cursor: pointer;
}

.anchorLink:hover {
  font-weight: 600;
}

.anchorWithoutStyles {
  text-decoration: none;
  color: unset
}

.greenAnchor {
  cursor: pointer;
  color: var(--dark-primary);
  text-decoration: underline;
  margin-left: 5px;
  
}
.errorText {
  font-size: 14px;
  color: var(--error-red)
}

.errorText::first-letter {
  text-transform: capitalize
}

.successBar, .errorBar {
  padding: 1em;
  margin: 1em;
  border: 1px solid var(--dark-primary);
  border-radius: 4px;
  background: var(--light-primary);
  color: var(--dark);
  display: flex;
  justify-content: center;
}
.errorBar {
  background: var(--light-error-red);
  border-color: var(--error-red);
  color: var(--error-red)
}

.showcase {
  background: var(--green-grey)!important
}

.form{
    width: 400px;
    border: 1px solid var(--light-grey);
    padding: 2em;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
    border-radius: 16px;
    scroll-behavior: smooth;
}

.formSection {
  background-color: var(--white);
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 16px;
}

.form.extend {
  min-height: 400px;
  height: auto;
}
@media (max-width: 500px){
  .form{
    width: 100%;
  }
}
.formHelperText {
  font-size: var(--small-font)
}

.formTitle {
  font-weight: 600;
  font-size: var(--strong-font);
  text-align: center;
}

.formTitle.white {
  color: var(--white)
}

.formTitle.space {
  margin-bottom: 1em
}

.formLabel {
  text-transform: capitalize;
  margin-bottom: 5px;
}
.formNotes {
  font-size: var(--small-font);
  color: var(--info-blue);
}

.formNotes.info {
  display: flex;
  align-items: center;
}
.authScreen {
    justify-content: center;
    align-items: center;
    background: var(--very-light-grey)
}

.imageFrame {
  height: 225px;
  min-width: 305px;
  overflow: hidden;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px){
  .imageFrame {
    min-width: 0%;
    width: 100%;
    margin-right: 0;
  }
}
.deleteForm {
  height: 250px;
  width: 100%
}

.checkoutField {
  border: 1px inset var(--light-grey);
  border-radius: 16px;
  background: var(--white);
  padding: 1em;
}

.checkoutField.flex {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 300px){
  .checkoutField.flex {
    display: block;
  }
}

.lineItem {
  font-size: var(--small-font);
  display: flex;
  justify-content: space-between;
  
  color: var(--dark-grey);
  margin-bottom: 2px;
}

.lineItemLabel {
  max-width: 200px;
  display: flex;
  margin-right: 1em;
}

.lineItemQuantity {
  margin-right: 10px
}

.calculationSection {
  border-bottom: 1px solid var(--grey);
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  overflow: auto;
  max-height: 30vh;
}
.providerLogo {
  max-width: 8em;
  max-height: 5em;
  height: auto;
}

@media (max-width: 350px){
  .providerLogo{
      max-width: 100%;
  }
}

.checkoutOption {
  padding: 10px;
  border: 1px outset var(--light-grey);
  border-radius: 16px;
  margin-bottom: 7px;
  font-size: var(--med-font);
  background: var(--white);
}

.checkoutOption:hover {
  filter: brightness(90%)
}
.checkoutOption.selected {
  background-color: var(--light-primary);
  border-style: inset
}

.checkoutOption.unavailable {
  filter: contrast(0.3);
  border-style: solid;
  border-color: var(--inactive-grey);
  color: var(--grey);
}

.infoHighlight {
    color: var(--info-blue)
}

.dashboardScreen {
  padding: 1em;
  background-color: var(--white);
  border: 1px outset;
  border-radius: 16px;
  margin-bottom: 1em;
}

.dashboardSideMenu {
  min-height: calc(100vh - 40px);
  width: 20%;
  float: left;
  border: 1px outset;
  background: var(--white);
}

.dashboardScreenArea{
  width: 80%;
  padding: 1em;
  min-height: calc(100vh - 40px);
  overflow: auto;
}

@media (max-width: 700px){
  .dashboardSideMenu{
      display: none;
  }
  .dashboardScreenArea{
    width: 100%
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileNavigationBar {
  display: flex;
  justify-content: space-around;
  border: 1px outset var(--dark);
  box-shadow: -0px -1px 2px var(--grey);
  padding: 7px 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--dark);
  z-index: 200;
}

.phoneOnly {
  display: none
}

.tooltip {
  max-width: 30vw
}
@media(max-width: 700px){
  .phoneOnly{
    display: block
  }
  .phoneOnly.flex{
    display: flex
  }
}

@media (max-width: 500px){
  .phoneFormContainer {
      padding-left: 1em;
      padding-right: 1em;
  }
}

.statsPanel {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
}

@media(max-width: 700px){
    .statsPanel {
        display: block
    }
}

.navigationList {
  height: 45vh;
  max-height: 45vh;
  overflow-y: auto;
  background: var(--very-light-grey);
  border: 1px solid var(--light-grey);
  padding: 5px;
  border-radius: 16px;
}

.navigationListHeader{
  display: flex;
  align-items: center;
  color: var(--secondary)
}
@media(max-width: 700px){
  .navigationList {
    background: none;
    overflow: visible;
    max-height: none;
    border: none
  }
}

.responseIcon {
  background-color: var(--light-grey);
  height: 18vh;
  width: 18vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18vh
}

.responseIcon svg {
  color: var(--white);
  font-size: 10vh
}

.iconCircle {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid var(--dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--very-light-grey);
}

.iconCircle svg {
  color: var(--grey);
  font-size: 40px;
}
.cardResultContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--very-light-grey);
}

.cardResultContainer img {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
}


.cardResultTitle {
  font-size: 1.8em;
  margin: 0.2em 0;
  font-weight: 100;
  color: var(--grey);
}

.cardResultTitleContainer {
  display: flex;
  align-items: center;
  text-align: center;
}

.cardResultInnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5em 2em;
  background: white;
  border: 1px outset;
  border-radius: 16px;
  max-width: 90%;
}

@media(max-width: 700px){
  .cardResultContainer img {
    height: 80px;
  } 

  .cardResultInnerContainer {
    padding: 3em 2em;
  }
}

.cardResultNext {
  font-size: var(--small-font);
  text-align: justify
}

.info-bubble {
    background: var(--very-light-grey);
    border-radius: 1em;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    color: var(--dark);
}

.info-bubble span {
  font-weight: 600
}

.balance {
  font-family: monospace;
}

.balance.pop {
  font-weight: 600;
  font-size: 17px;
}

.balance.header {
  font-size: 32px;
  font-weight: 600;
}

.balance.bold {
  font-weight: 600;
}


.balance.credit {
    color: var(--credit-green)
}
.balance em {
  font-size: 0.75em;
  font-style: normal;
}

.negative-balance {
  color: var(--sale-red);
}

/* react tags */
.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInputField{
  padding: 0.5em;
  width: 100%;
  border: 0.5px solid var(--light-grey);
  background: var( --very-light-grey);
  border-radius: 4px;
}

.ReactTags__tags {
  position: relative;
  z-index: 1;
}

.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: var(--very-light-grey);
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 3px 5px;
  border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position:absolute;
  max-height: 200px;
  overflow-y: auto;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
  margin: 0;
  padding: 0;
  border: 1px solid var(--grey);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: var(--light-primary);
  cursor: pointer;
}

.socialMetric {
  display: inline-flex;
  align-items: center;
  color: var(--dark-grey);
  font-family: 'Roboto';
  font-size: 0.8em;
  font-weight: bold;
}

.socialMetric svg {
  height: 18px;
}

.socialMetric.interactive {
  cursor: pointer;
}

.orderItemModal {
    display: flex;
    flex-direction: column;
    min-width: 400px;
}

.innerOrderItemModal {
  display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.orderItemCounter {
  padding: 0.5em;
  text-align: center;
}

@media(max-width: 700px){
  .socialMetric {
    font-size: 0.8em
  }

  .orderItemModal {
    min-width: unset;
  }
}

.actionsDropdown {
  padding: 0.5em;
  margin-top: 5px;
  background: var(--white);
  border: 1px outset;
  border-radius: 16px;
}

.checkoutTitle {
  display: flex;
  align-items: center;
}

.checkoutTitle > img{
  height: 40px;
  margin: 0 5px;
  width: auto;
}

.checkoutTitle.med > img{
  height: 80px;
}

.amount {
  font-family: monospace;
  font-size: 18px;
}

.amount label {
  white-space: nowrap;
  padding: 5px;
}

.amount input {
  font-family: monospace;
  border: 0.5px solid;
  background: var(--very-light-grey);
  border-radius: 4px;
  font-size: 18px;
  padding: 5px;
}
.amount.big {
  font-size: 60px;
}

.amount.fit input{
 width: 300px;
}
.amount.flex {
    display: flex;
    justify-content: center;
}
.amount.spaced {
  margin: 20px 0
}
.amount.center {
  text-align: center;
}
.amount.big input {
 
  font-size: 60px;
  padding: 0;
}

.amount.big label {
  padding: 0
}

@media (max-width: 700px){
  .amount.big {
    font-size: 48px;
  }
  .amount.big input {
    width: 200px;
    font-size: 48px;
  }

}

@media (max-width: 300px){
  .amount.big input {
    width: 130px;
  }

}

.spaced {
  margin: 1em
}

.spaced-05em {
  margin: 0.5em
}

.instructionTitle {
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: underline;
}

.instructions {
  color: var(--info-blue);
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 600;
  font-size: var(--small-font);
}

.amountHighlight {
  background-color: var(--light-primary);
  border: 1px solid var(--dark-primary);
  border-radius: 32px;
  font-size: 1.5em;
  
  padding: 0.5em;
  
}

.createPanel {
  display: flex;
  justify-content: center;
}

.modalContent {
  position: relative;
  border: 1px outset rgb(204, 204, 204);
  background: var(--white);
  padding: 20px;
  height: 100%;
  width: fit-content;
  border-radius: 16px;
  outline: none;
  max-width: 100%;
}

.modalContent.fit {
  height: fit-content;
  max-height: 100%;
  overflow: auto;
}

.modalContent.contained {
  padding: 0;
}

.modalContent.menu {
  height: 100vh;
  margin-left: 120vw;
  width: 100%;
  outline: none;
  transition: margin-left 500ms;
  padding: 0;
  background: var(--very-light-grey);
  overflow: hidden;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modalContent.slide {
  margin-left: 0;
}
.modalOverlay.menu {
  padding: 0% 0% 0% 60%;
  z-index: 201;
}

@media(max-width: 700px){
  .modalOverlay.menu {
      padding: 0% 0% 0% 20%;
  }
}

.modalOverlay.noPadding {
  padding: 0;
}

@media(max-width: 700px){
  .modalOverlay.noPadding {
      padding: 1%;
  }
}
.modalInnerContainer{
  height: 95%;
  overflow: auto;
  overscroll-behavior-y: none;
}

.modalInnerContainer.contained{
  display: flex;
  flex-direction: column;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:var(--translucent);
  z-index: 201;
  padding: 5% 10%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.modalOverlay.fullscreen {
    padding: 1%
}

.modalOverlay.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalCloseButtonContainer {
  text-align: right;
  padding: 5px 5px 0 0;

}

.modalTitleContainer {
  display: flex;
  justify-content: space-between;
}
@media(max-width: 700px){
  .modalContent {
      width: 100%;
  }
  .modalOverlay {
      padding: 1%;
  }
}

.modalTransparent {
  background: transparent;
  border: none;
}

@media(max-width: 400px){
  .modalTransparent {
    padding: 0px;
  }
}
.modalTransparent > .modalInnerContainer {
  padding-right: 0.5em;
}

.modalHeader {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px outset;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
  border-top: 2px inset;
  height: 65px
}

.modalInner.contained {
  overflow-y: auto;
  flex: 1
}
.dashboardContainer > div{
    margin-bottom: 2em;
}
/* sale styles */
.salePreviousPrice {
  color: var(--grey);
  text-decoration: line-through;
  margin-left: 10px;
  font-size: var(--small-font);
  font-weight: 400;
  font-style: italic;
}

.saleEventFlag {
  background-color: var(--sale-red);
  color: var(--white);
  padding: 5px;
  margin: 5px;
  width: fit-content;
  font-size: var(--small-font);
  font-weight: 600;
  text-transform: capitalize;
}

@media(max-width: 500px){
  .saleEventFlag {
      padding: 2px;
      font-size: 0.6em;
  }
}

.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--very-light-grey);
    cursor: pointer;
    border: 1px dashed;
    border-radius: 16px;
    padding: 1em;
    position: relative;
    min-height: 200px;
}

.upload .symbol{
  height: 70%;
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.upload svg {
  display: block;
  height: 70%;
  width: auto;
  color: var(--dark-grey);
}

.upload input[type="file"] {
  display: none;
}

.upload .button.white {
  background-color: transparent;
}
.upload:hover {
  background-color: var(--white);
}

.actionText {
  cursor: copy;
}
.actionText:hover{
  background-color: var(--light-primary);
}
.actionText:active {
  background-color: var(--action-blue);
}

.actionMenu {
  padding:  0 0.5em;
}

.actionMenu > div {
  margin-bottom: 0.5em;
}

.actionMenu.horizontal {
  padding: 0.5em 0;
  display: flex;
  justify-content: space-evenly;
}
.facebookIcon svg {
  color: var(--facebook-blue)
}

.whatsappIcon svg {
  color: var(--whatsapp-green)
}

.youtubeIcon svg {
  color: var(--youtube-red)
}

.imgIcon {
  height: 60px;
}
.imgIcon.big {
  height: 120px;
}

.iconTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
}
.iconTitle > img {
  height: 40px;
  margin: 0 5px;
  width: auto;
}

.slogan {
  color: var(--grey);
  font-size: 1.1em;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}

.slogan.primary {
  color: var(--primary);
}

.hero {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2Fcarousel%2Fgroceries-opt.jpg?alt=media&token=623ecb83-6252-458b-8e18-cc899d732ac0");
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  max-width: 100%;
  height: 80vh;
} 

.heroContent {
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--light-translucent);
  padding: 0.5em 1em;
  border-radius: 16px;
  margin: 1em;
}

.heroTitle {
  font-size: 50px;
  line-height: 1.1;
  font-weight: 800;
  font-family: Raleway, 'Lato';
  padding: 0.25em;
  border-radius: 16px;
  text-align: center;
}

.cta {
  border: 2px outset white!important;
  font-weight: 600;
  margin-top: 10px;
}

.cta:hover, .cta:active {
  background-color: var(--primary);
  border-color: var(--primary)!important; 
  filter: none;
}
@media(max-width: 1000px){
   .heroTitle {
      font-size: 1.75em;
  }
}

@media(max-width: 750px){

  .hero {
      max-height: 50vh;
      padding-top: 0;
  }
  .heroTitle {
      padding: 0.5em;
  }

  .cta {
    border: 2px outset white!important;
    font-weight: 600;
    font-size: 0.8em;
    min-height: 15px;
    height: fit-content;
    margin-top: 10px;
  }
}

.topRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1em
}

.noWrap {
  white-space: nowrap;
}

.transactionListTitle {
    font-weight: 600;
    font-size: 1.2em;
    margin: 1em 0.5em;
}

.transactionList {
  max-height: 100vh;
  overflow-y: auto;
} 

.tableList{
  max-height: 200px;
  overflow-y: auto;
  border: 1px inset;
  padding: 5px;
  border-radius: 8px;
  min-width: fit-content;
}

.errorShake {
  animation: shake 0.7s;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
} 

.mapContainer {
  height: 100%;
  width: 500px;
  overflow-y: hidden;
}
.mapContainer > iframe {
  height: 100%;
  width: 100%
}

@media(max-width: 700px){
  .mapContainer {
      width: 100%;
  }
}

.emptyText {
  display: flex;
  align-items: center;
  padding: 0.5em;
  white-space: nowrap;
}

.emptyText svg {
  margin-right: 5px;
}

.numberBadge{
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 2px
}

.numberBadge.dark {
  background-color: var(--dark);
  color: var(--white);
}

.statusTime {
  font-size: 0.9em;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.statusTime svg {
  font-size: 18px;
  margin-right: 5px;
}

.statHolder {
  display: flex;
  justify-content: space-around;
}

.card {
  border: 1px outset var(--light-grey);
  border-radius: 16px;
  padding: 1em;
  background-color: var(--white);
}

.clickable:hover {
  cursor: pointer;
  filter: brightness(90%);
}
/***** framework *****/

/* headings */
.h2 {
  font-size: 26px;
  font-weight: 600;
}

.h3 {
  font-size: 20px;
  font-weight: 700;
}

.h4 {
  font-size: 0.8em;
  font-weight: 600;
}

/* flex box */

.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* block-sizing */
.padding-1em {
  padding: 1em
}

.padding-05em {
  padding: 0.5em
}

.margin-left-05em {
  margin-left: 0.5em
}

.margin-right-05em {
  margin-right: 0.5em
}

.margin-top-05em {
  margin-top: 0.5em
}

.margin-bottom-05em {
  margin-bottom: 0.5em
}

.margin-top-1em {
  margin-top: 1em
}

/* general */

.white-space-nowrap {
  white-space: nowrap;
}

.display-inline-block {
  display: inline-block;
}
.border-1px-solid-light-grey {
  border: 1px solid var(--light-grey)
}

.width-100pct {
  width: 100%
}

.width-fit-content {
  width: fit-content
}

.min-width-100pct {
  min-width: 100%;
}

.max-height-50vh {
  max-height: 50vh;
}

.overflow-y-auto {
  overflow-y: auto;
}
.label {
  font-size: 14px;
}
/* text */

.text-align-left {
  text-align: left
}

.text-align-center {
  text-align: center;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.color-grey {
  color: var(--grey)
}

.color-link-blue {
  color: var(--link-blue)
}

.font-size-14px {
  font-size: 14px;
}

.font-size-12px {
  font-size: 12px;
}


.height-fit-content {
  height: fit-content;
}
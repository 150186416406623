.container {
    background: var(--white);
    border: 1px outset;
    margin-bottom: 0.5em;;
}
.item {
    display: flex;
}

.imageFrame {
    height: 100px;
    width: 100px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageFrame > img {
    max-height: 100%;
    max-width: 100%;
}

.itemDetails, .changes {
    padding: 10px 5px 0 5px
}
.itemDetails > span {
    margin-right: 4px;
}

.changeContainer {
    display: flex;
    align-items: center;
}

.changeBadge {
    margin-right: 4px
}

.changeText {
    font-weight: 600;
}

.changeText.primary, .action.primary {
    color: var(--dark-primary)
}

.changeText.warning {
    color: var(--dark-warning-yellow)
}

.actions {
    display: flex;
    justify-content: flex-end;
    border-top: 0.5px solid var(--light-grey);
}

.action {
    padding: 5px 1em;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 0.5px solid var(--light-grey);
    height: 41px;
    background-color: var(--white);
}

.action:hover {
    filter: brightness(90%)
}

.action.active:hover{
    font-weight: 600;
    cursor: pointer;
}

.action label {
    margin-top: -10px
}

@media(max-width: 400px){
    .action {
        min-width: unset;
        font-size: 0.9em;
    }

    .changeContainer, .changeContainer :global(.strong){
        font-size: 0.9em;
    }

    .itemDetails :global(.strong){
        font-size: 1.1em;
    }
}

@media(max-width: 300px){
    .action {
        font-size: 0.7em;
    }

    .changeContainer, .changeContainer :global(.strong), .container :global(.badge){
        font-size: 0.8em;
    }


}
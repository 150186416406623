.modal {
    height: 150px;
    width: 400px;
    max-width: 100%;
    /* box-shadow: 0px 0px 5px 1px white; */
    padding-bottom: 10px
}

.content {
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modalOverlay {
    display: flex;
    align-items: center;
}

.content button {
    min-width: 100px;
}
.title {
    display: flex;
    align-items: center;
}

.title svg {
    margin-right: 5px
}

@media(max-width: 400px){
    .modal {
        min-width: unset;
    }
}
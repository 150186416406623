.container {
    
}

.sellerContainer {
    padding: 1em 0;
    width: 100%;
}

.sellerIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2px 1em;
    text-transform: capitalize;
}
.sellerIcon:hover {
    cursor: pointer;
    filter: brightness(80%);
}

@media(max-width: 400px){

    .sellerContainer {
        padding-top: 0.5em;

    }

}
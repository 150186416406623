.container {
    margin-bottom: 0.5em;
}

.seller {
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.seller > div {
    margin-right: 10px;
}

.sellerIcon {
    display: flex;
    align-items: center;
}

.sellerIcon > span {
    margin-right: 10px
}

.itemInfo {
    margin-bottom: 0.5em;
}

.itemInfo > div {
    display: flex;
    font-weight: 600;
    align-items: center;
}

.itemInfo svg {
    margin-right: 5px
}
.deliveryContainer {
    display: flex;
    margin-top: 0.5em;
    flex-wrap: wrap;
}



@media (max-width: 600px){
    .deliveryContainer {
        margin-top: 1em
    }   
}

@media (max-width: 400px){
    .seller {
        display: block;
        margin-bottom: 1em;
    }
}
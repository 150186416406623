.selectedAddress {
    text-transform: capitalize;
    font-size: 1.1em;
    font-weight: 100;
    margin-bottom: 0.5em
}

.deliverToCity {
    margin: 5px
}

.directions, .phone {
    font-size: var(--small-font);
    margin-bottom: 1em;
}

.toggleDirections {
    margin-bottom: 1em;
    display: block;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
} 

@media(max-width: 700px){
    .deliverTo {
        display: block;
    }
    .deliverToCity {
        display: block;
        margin-left: 2em
    }
}
    
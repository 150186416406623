/* schedule styles */

.schedule {
    margin: 0.5em 1em;
    min-height: 250px;
}

.scheduleTitle {
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.5em;
}

.scheduleSlots {
    border: 1px outset;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 2px var(--light-grey);
}

/* slot styles */

.slotContainer {
    min-width: 300px;
    width: 100%;
    height: 55px;
    padding: 0 0.5em;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--grey);
    align-items: center;
    background-color: var(--white);
}

.slotContainer.selected {
    background-color: var(--light-primary);
}

.slotContainer.unavailable {
    background-color: var(--light-grey);   
}
.slotDetails {
    text-align: left;
}
.slotType {
    text-transform: capitalize;
    
}

.slotTimeRange {
    font-size: 0.9em;
    font-weight: 600;
}

.slotButton {
    padding: 7px;
    border: 1px solid var(--dark-primary);
    color: var(--dark-primary);
    font-weight: 600;
    background-color: var(--white);
    border-radius: 8px;
    cursor: pointer;
    width: 100px;
}

.slotContainer.selected .slotButton {
    background-color: var(--primary);
    color: var(--black)
}

.slotContainer.unavailable .slotButton{
    /* font-size: 0.9em; */
    border-color: var(--dark-grey); 
    color: var(--light-grey);
    background-color: var(--grey);   
    cursor:not-allowed;
    user-select: none;
}

.slotButton:hover {
    background-color: var(--light-primary);
}
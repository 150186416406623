.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.container input {
    flex: 1;
    padding: 0.2em;
    border: 0;
    outline: none;
}

.container > div {
    display: flex;
    align-items: center;
    padding: 0 2px ;
    margin-bottom: 2px;
    font-size: 14px;
}

.valid, .valid input {
    background-color: var(--light-primary);
    border-color: var(--primary);
}

.areaCode {
    padding: 0.25em;
    border-radius: 4px;
    border: 1px outset;
    font-size: var(--small-font);
    max-width: 150px;
    text-transform: capitalize;
}

@media(max-width: 700px){
    .areaCode{
        max-width: 75px;
    }
}
.container {
    padding: 1em;
    border: 1px outset var(--light-grey);
    border-radius: 16px;
    width: 280px;
    background: var(--white)
}

.section {
    border-bottom: 1px solid var(--grey);
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    overflow: auto;
    max-height: 30vh;
}


@media(max-width: 1270px){
    .container{
        width: 100%;
    }
}

@media(min-width: 1270px){
    .container{
        position: fixed;
        right: 0;
    }
}
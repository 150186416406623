.mainContent {
    /* display: block; */
    /* max-width: 1450px; */
}

.sellerContent {
    flex: 1;
    max-width: 85vw;
}

@media (max-width: 1000px){
    .sellerContent {
        max-width: 75vw
    }
}

@media (max-width: 700px) {
    .sellerContent {
        max-width: 100vw;
    }
}
.container {
    padding: 0.5em;
    border-right: 2px outset var(--white);
    min-width: 210px;
    background: var(--white);
    z-index: 100;
    width: 15vw;
}

@media (max-width: 1000px){
    .container{
        width: 25vw
    }
}
@media (max-width: 700px){
    .container{
        display: none;
    }
}
.modal {
    height: fit-content;
    text-align: left;
    width: 350px;
}

.modalOverlay {
    padding: unset
}

.modal :global(.form){
    border: none;
    height: fit-content;
    padding: 0;
    width: 100%;
}

.title {
    text-transform: capitalize;
    text-align: center;
    justify-content: center;
    display: flex;
}

@media(max-width: 700px){
    .modal{
        height: 100%;
    }
}
.container {
    padding: 1em;
    padding-bottom: 10em;
    padding-left: 0;
    width: 100%;
    background: var(--green-grey);
}

.heading {
    text-transform: capitalize
}

.container > :global(.title){
    padding: 0.5em 1em;
}

@media(max-width: 700px){
    .container{
        padding: 0 0 10em 0;
    }

}
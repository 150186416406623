.container {
    width: 100%;
}
.innerContainer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.paginationContainer{
    display: flex;
    justify-content: center;
}

.showing {
    margin-left: 1em;
}

@media (max-width: 700px){
    .innerContainer{
        display: block;
    }
}
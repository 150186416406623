.container {
    background: var(--primary-gradient);
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    max-width: 100%;
    height: 300px;
    overflow-x: auto;
} 

.elements {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1
}

.elements button, .elements input, .element > div {
    display: block;
    width: 100%;
    border-radius: 4px;
}


.element {
    width: 25%;
    font-family: 'Raleway';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscribed {
    justify-content: center;
}

.element.subscribed > div {
    display: flex;
    justify-content: center;
} 
button.light {
    background: white;
    color: var(--dark)
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    color: white;
}

.title {
    color: white;
    font-size: 40px;
    font-family: 'Raleway';
    text-align: center;
}

@media(min-height: 700px){
    .container {
        height: 400px;
    }
}

@media(max-width: 1200px){
    

    .element {
        font-size: 0.9em;
    }

    .elementCircle{
        height: 8vw;
        width: 8vw;
        border-radius: 12vw;
        min-width: 70px;
        min-height: 70px;
    }
    .content {
        padding: 1em;
        justify-content: space-between;
    }
    .title {
        font-size: 1.75em;
    }

}

@media(max-width: 1000px){
    .element {
        width: 33%
    }
}
@media(max-width: 750px){
    .container {
        height: fit-content;
        padding: 1em;
    }
    .elements {
        /* max-height: 150px */
        flex-direction: column
    }
    .element {
        margin-bottom: 1em;
        width: 50%;
    }
    
    .title {
        font-size: 1.4em
    }

}

@media(max-width: 350px){
    .title{
      /* top: 0;  font-size: 0.8em */
    }
}

@media(max-width: 300px){

}
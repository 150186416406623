.currentImage {
    display: flex
}

.frame {
    min-width: 300px;
}

.imageList {
    display: flex;
    padding: 1em 0;
}

.imageList > div {
    margin-right: 0.5em;
}

.imageList > div:hover {
    filter: brightness(80%)
}
.new {
    border: 1px solid var(--grey);
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new:hover {
    background-color: var(--light-grey);
}

@media(max-width: 400px){
    .frame {
        min-width: 200px;
    }
}
.mainContent {
    flex-direction: column;
    align-items: center;
    background: var(--very-light-grey);
}

.linkContainer {
    width: 100%;
}
.innerContainer {
    display: flex
}

@media(max-width: 700px){
    .mainContent{
        padding-left: 1em;
        padding-right: 1em;
    }
}
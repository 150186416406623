.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--light-grey);
    background-color: var(--white);
    padding: 5px;
    cursor: pointer;
    margin: 5px;
    border-radius: 30px;
}

.container:hover{
    filter: brightness(90%)
}
.container svg {
    color: var(--dark-primary);
}
.label svg{
    margin-right: 0.5em;
}

.label{
    display: flex;
    padding: 5px;
}
.directions, .phone {
    font-size: var(--small-font)
}

.modal {
    padding: 20px 0;
}
.modal > div > span {
    margin-right: 20px;
}

.modalTitle{
    text-align: center
}

.addButton{
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px
}
.addressContainer {
    width: 400px;
    padding: 1em;
    background: var(--white);
    cursor: pointer;
    margin: 0.5em;
    border-radius: 8px;
}

.addressContainer:hover {
    filter: brightness(90%);
}

.addressContainer.selected{
    background-color: var(--light-primary)
}

.addressOption{
    display: flex;
}
.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.buttons button:first-of-type{
    margin-right: 5px;
}

@media(max-width: 700px){
    .addressContainer{
        width: 100%
    }
}
.container {
    display: flex;
    font-size: 3em;
}

.container.small {
    font-size: 1em;
}


.valueContainer {
    margin: 0 10px;
    text-align: right;
    text-transform: capitalize;
}

.container.small .valueContainer{
    margin: 3px;
}

.valueContainer > div {
    font-size: 15px;
}

.container.small .valueContainer > div{
    font-size: 9px;
}
.units {
    text-transform: uppercase;
    font-size: 8px;
    margin-left: 2px;
}

@media(max-width: 700px){
    .container{
        font-size: 2em;
    }
}
.modal {
    height: fit-content;
}

.thumbnailContainer {
    position: relative;
}
.imageCount{
    position: absolute;
    background-color: var(--action-blue);
    border: 1px solid var(--white);
    color: var(--white);
    bottom: -5px;
    right: 20px; 
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: var(--small-font);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    margin-bottom: 0.5em;
    text-transform: capitalize;
}
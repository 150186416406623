.container {
    scroll-behavior: smooth;
}

.title {
    margin-top: 1em;
}

.instructionsPrompt.wiggle {
    animation: wiggle 2s ease 5s 2;
}

@keyframes wiggle {
    0% {transform: rotate(3deg);}
    10% {transform: rotate(-3deg);}
    20% {transform: rotate(3deg);}
    40% {transform: rotate(-2deg);}
    50% {transform: rotate(0deg);}
}

.info {
    display: flex;
    margin-bottom: 5px;
    align-items: baseline;
    font-size: var(--small-font);
}

.only {
    font-weight: 600;
}
.info :global(.badge) {
    margin-right: 5px;
}

.notSure {
    margin: 1em 0;
    font-weight: 600;
}


@media (max-width: 500px){
    .info {
        margin-bottom: 10px;
    }
}
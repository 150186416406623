.container {
    background-color: var(--white);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px outset var(--very-light-grey);
    cursor: pointer;
}
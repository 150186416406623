.modal {
    min-width: 300px;
    height: fit-content;
    text-align: center;
    max-height: 100%;
}

.title {
    margin-bottom: 0.5em;
    text-transform: capitalize;
}

.buttonHolder {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.buttonHolder button {
    margin-right: 5px;
}

@media(max-width: 400px) {
    .buttonHolder button {
        padding: 2px 0.6em;
    }
}
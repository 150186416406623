.container {
    display: flex;
    cursor: pointer;
    color: var(--dark-primary);
    user-select: none;
}

.socialButtonIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socialButtonIcon.unselected svg{
    transition: transform 0.5s;
}
.socialButtonIcon.unselected svg:hover{
    background: var(--light-primary);
    box-shadow: 0px 0px 7px 5px var(--light-primary);
    border-radius: 30%;
    transform: scale(1.5);
    /* animation: shake 2s; */
}   

.socialButtonLabel {
    /* text-transform: uppercase; */
    font-size: 10px;
    font-weight: 600;
}

.socialButtonCount{
  border-radius: 50%;
  height: 20px;
  width: fit-content;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}
.socialButtonCount:hover {
  background-color: var(--light-primary);
}
/* @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  } */

  /* @keyframes wiggle {
    0% {transform: rotate(10deg);}
    25% {transform: rotate(-10deg);}
    50% {transform: rotate(20deg);}
    75% {transform: rotate(-5deg);}
    100% {transform: rotate(0deg);}
  } */
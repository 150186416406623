.container {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2Fcarousel%2Fgroceries-opt.jpg?alt=media&token=623ecb83-6252-458b-8e18-cc899d732ac0");
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    /* flex-direction: column; */
    /* padding: 1em; */
    justify-content: center;
    align-items: center;
    padding-top: var(--searchBarHeight);
    max-width: 100%;
    height: 80vh;
} 

@media (max-width: 700px){
    .container{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2Fcarousel%2Fgroceries-hero-mobile.jpg?alt=media&token=2952975a-155c-464e-9052-0360be988190");
    }
}

.content {
    text-align: center;
    width: 80%;
    display: flex;
    justify-content: center;
}

.title {
    font-size: 50px;
    line-height: 1.1;
    font-weight: 800;
    font-family: Raleway, 'Lato';
    color: white;
    background-color: #0000006b;
    width: fit-content;
    padding: 0.25em;
    border-radius: 16px;
}

.cta {
    border: 2px outset white!important;
    font-weight: 600;
}

.cta:hover, .cta:active {
    background-color: var(--primary);
    border-color: var(--primary)!important; 
    filter: none;
}

@media(max-width: 1000px){

    .content {
        padding: 1em;
    }
    .title {
        font-size: 1.75em;
    }

}

@media(max-width: 750px){

    .container {
        max-height: 150px;
        padding-top: 0;
        margin-top: var(--searchBarHeight);
    }
    .title {
        font-size: 1em;
        padding: 0.5em;
    }

    .cta {
        border: 2px outset white!important;
        font-weight: 600;
        font-size: 0.8em;
        min-height: 15px;
        height: fit-content;
        margin-top: 10px;
    }
}

@media(max-width: 350px){
    .title{
        font-size: 0.8em
    }
}

@media(max-width: 300px){
    .button {
        font-size: 0.4em
    }
}
.block{
    background: white
}

.container {
    height: 100vh;
    background: var(--primary-gradient);
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 210;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.center img {
    width: 15vw;
    height: auto;
    min-width: 120px
}

.animation {
    padding: 0.5em
}

.slogan {
    color: white;
    font-weight: normal;
}
@media (max-width: 700px){
    .center img {
        min-width: 100px
    }
}
.innerContainer {
    padding: 1em;
    background-color: var(--very-light-grey);
    border-radius: 16px;
    border: 1px inset;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
}

.title {
    color: var(--white);
    margin-bottom: 63px;
}

.ask {
    display: flex;
    margin-bottom: 1em;
}

.ask > div {
    margin-right: 1em;
}

.breakdownContainer {
    background-color: var(--white);
    padding: 0.5em;
    border-radius: 16px;
}

@media (max-width: 400px) {
    .title {
        font-size: 1.2em;
        margin-bottom: 50px;
    }
}
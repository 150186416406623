.button {
    color: black;
    font-weight: 600;
    background: var(--nbd-field-yellow);
    border: 1px outset var(--dark-warning-yellow);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button > span {
    margin-right: 5px
}
.modal {
    max-width: 350px;
    text-align: left;
}

.modalOverlay {
    padding: 1%;
}
.image {
    max-width: 200px;
    max-height: 200px;
}

.imageFrame {
    margin: 0.5em 0;
}
.productDetails {
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal .button {
    width: 100%;
}
.container {
    position: relative;
}
.innerContainer {
    overflow-y: hidden;
    display: flex;
    overflow-x:auto;
    width: 100%;
    position: relative;
    scroll-behavior: smooth;
} 


.arrow {
    position: absolute;
    top: calc((100%/2) - 33px);
    min-height: 35px;
    height: 65px;
    width: 65px;
    background: var(--white);
    border-radius: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    cursor: pointer;
    z-index: 1;
    box-shadow: 0px 0px 10px 2px var(--light-grey);
}
.arrow:active, .arrow:focus, .arrow:hover {
    /* box-shadow: 0px 0px 8px 3px var(--primary); */
    background: var(--very-light-grey);
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.arrow svg {
    font-size: 45px;
}
.left {
    left: 3vw
}
.right {
    right: 6vw
}
.hidden {
    display: none
}

@media(max-width: 700px){
    .arrow{
        display: none
    }
    .container{
        padding: 5px
    }
}
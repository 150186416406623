.innerContainer {
    display: flex;
    height: fit-content;
}

.modal {
    padding: 0;
    overflow: hidden;
    height: fit-content;
}

.modal :global(.modalInnerContainer){
    height: 100%;
}

.modal :global(.modalCloseButtonContainer){
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}

.modalOverlay {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media{
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 60vw;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}
.iframe {
    max-width: 100%;
    border: none;
}

.comments {
    width: 300px;
}

@media(max-width: 700px){
    .innerContainer {
        flex-direction: column;
    }

    .media {
        height: fit-content
    }
}
.container {
    background-color: var(--white);
    padding: 1em;
    border: 1px solid var(--light-grey);
    border-radius: 16px;
    width: 300px;
    box-shadow: 5px 5px 3px var(--light-grey);
}

.header {
    display: flex;
    align-items: center;
}
.imgIcon {
    height: 50px;
}

.total, .container select {
    margin-top: 0.5em
}

.info {
    font-size: var(--small-font);
    margin-top: 1em
}

.name {
    font-weight: 600;
}

.deliveryInfo {
    border: 1px inset var(--light-grey);
    padding: 5px;
    border-radius: 5px;
    background-color: var(--very-light-grey);
}
.container {
    height: fit-content;
    margin: 1em 0;
}
.resetPassword {
    height: 50px;
    display: flex;
    align-items: flex-end;
}

.resetPassword > .anchorLink {
    margin-left: 3px;
  
}

.anchorLink {
    font-weight: 600;
    color: var(--info-blue)
}

.createAccountButton {
    margin-top: 0.5em;
}
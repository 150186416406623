.container {
    width: 300px;
    margin: 10px;
}

@media(min-width:1300px){
    .container {
        min-width: 22%
    }
}
@media(max-width:1200px) {
    .container {
        min-width: 220px;
        max-width: 33%;
    }
}
@media(max-width: 1000px){
    .container {
        min-width: 200px;
        max-width: 45%;
    }
}
@media(max-width: 700px){
    .container {
        margin: 3px;
        max-width: 45%;
        min-width: 45%;
        /* height: 40vh; */
    }
}
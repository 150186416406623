.hidden {
    visibility: hidden;
}

.modal {
    max-width: 100%;
    width: 500px;
    background: var(--white);
    max-height: 650px;
    background: none;
    border: none;
    height: fit-content;
    color: var(--white);
    text-align: center;
}

.modal svg {
    color: var(--white)!important
}
.innerModal > div {
    margin-bottom:  1.5em
}
.modalOverlay {
    align-items: center;
    background-color: var(--dark-translucent);
}

.payWith{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
}

.payWith > img {
    margin: 0 0.5em;
}

.clickToContinue {
    background: transparent;
    color: var(--white);
    font-weight: 600;
    text-decoration: underline;
    border: none;
    cursor: pointer;
}

@media(max-width: 700px) {
    .payWith {
        font-size: 1rem;
    }
}

@media(max-height: 700px){
    .modalOverlay {
        padding-top: 2%;
        padding-bottom: 2%
    }
}

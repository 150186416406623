.container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;
    margin-bottom: 1em;
    background: var(--white);
    border: 1px outset;
    border-radius: 16px;
}

.image {
    max-height: 400px;
    height: 400px;
    width: auto;
    margin: 1em 2em;
    max-width: 600px;
}

.details {
    padding: 0 2em;
    min-width: 30%;
    max-width: 50%;
}

.details .cartButton, .details .cartButton button, .stockMessage button {
    width: 100%;
    margin-bottom: 1em;
}
.description {
    text-align: justify;
    border: 1px inset;
    background: var(--very-light-grey);
    padding: 1em;
    border-radius: 4px;
    max-width: 25vw;
}
.price {
    font-size: var(--title-font);
    font-weight: 600;
}

.productTitle {
    max-width: 40vw;
    
}
.stockMessage {
    margin-bottom: 1em
}
.metrics{
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
}
.metrics > div {
    margin-right: 1em;
}

.quantity {
    margin-bottom: 1em
}

.sellerName {
    text-transform: capitalize
}

.characteristics {
    display: block;
}

.frame .characteristics {
    display: none
}

.charSelect {
    background: var(--light-primary);
    padding: 5px;
    border-radius: 6px;
    border: 1px outset var(--light-grey);
    height: fit-content;
    width: 200px;
}

.calculator {
    padding-top: 1em;
}

.sale .price {
    color: var(--sale-red)
}

.fromSeller {
    display: flex;
    align-items: center;
}

.fromSeller > span {
    margin-right: 5px;
}

.characteristics {
    max-width: 350px;
    overflow-x: auto;
}
@media (max-width: 1000px){
    .container{
        flex-direction: column;
        align-items: center;
    }
    .image{
        height: 300px;
        width: auto;
        margin: 0.5em;
    }
    .frame {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .details{
        padding-bottom: 1em;
        max-width: unset;
    }

    .frame .characteristics {
        display: block
    }

    .details .characteristics{
        display: none
    }

    .details {
        width: 100%
    }
    .container p {
        margin: 5px
    }
}

@media (max-width: 700px){
    .image{
        height: 35vh;
        width: auto;
        max-width: 80%;
        max-height: 35vh;
        
    }
    
    .container .productTitle {
        margin: 0;
        max-width: unset;
    }
    
    
    .container p {
        margin: 5px
    }
    .description {
        font-size: var(--small-font);
        max-width: unset;
    }

    .productTitle{
        text-align: center;
        padding: 0 0.5em;
    }

    .characteristics {
        padding: 0 1em;
    }
}

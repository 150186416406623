.container {
    display: inline-block;
    position: relative;
}

.menu {
    position: absolute;
    background-color: var(--white);
    z-index: 202;
    right: 0;
    top: 0;
    border-radius: 8px;
    border: 1px solid var(--light-grey);
    box-shadow: 0px 1px 3px 1px var(--light-grey);
    overflow: hidden;
    padding: 0.5em 0;
}

.menuItem {
    min-width: 200px;
    padding: 0.5em 1em;
    cursor: pointer;
    background-color: var(--white);
    text-align: left;
    max-width: 300px;
}

.menuItem:hover {
    filter: brightness(90%)
}
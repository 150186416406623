.container {
    background: var(--primary-gradient);
    height: fit-content;
    height: 40vh;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}

.container.hasImage {
    height: fit-content;
}

.imageContainer {
    background: var(--white);
}

.container > img {
    width: 100%;
    max-width: 100vw;
}

.editButton {
    position: absolute;
    bottom: 1em;
    right: 2em;
}

.editAdvice {
    display: block;
    font-size: var(--tiny-font);
}
@media(max-width: 1000px){
    .container {
        height: 20vh;
    }
}

@media(max-width: 700px){
    .container {
        height: 15vh;
    }
}
.modal {
    height: fit-content;
    width: fit-content;
    min-width: 300px;
}

.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
}
.shareOption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 1px solid var(--light-grey);
    font-size: 0.7rem;
    background-color: var(--very-light-grey);
    cursor: pointer;
    margin: 0.5em;
}

.shareOption:hover {
    background-color: var(--light-primary);
}

.shareOption:active {
    border: 3px solid var(--primary);
}
.shareOption svg{
    font-size: 2.5rem;
}

.shareOptionList {
    padding: 1em;
    display: flex;
    justify-content: space-evenly;
}


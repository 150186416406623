.container {
    padding: 0.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: 0.9em;
    cursor: pointer;
    background: white;
    margin-bottom: 5px;
    border-radius: 30px;
}


.profile {
    display: flex;
    align-items: center;
}

.profile span {
    margin-left: 5px
}

.container:hover {
    filter: brightness(90%)
}
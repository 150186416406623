.container > div {
    margin-bottom: 5px;
}
.list {
    margin-right: 1em;
    height: fit-content;
    max-height: 400px;
    min-width: 350px;
    overflow-y: auto;
    border: 1px solid var(--light-grey);
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

.productContainer{
    width: 280px;
    margin: 0 5px 10px 0;
    border: 1px outset var(--light-grey);
    border-radius: 16px;
    text-align: right;
} 

.product {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.product img {
    max-height: 100px;
    max-width: 80px;
}

.productTitle {
    font-weight: 500;
    text-align: right;
    overflow-wrap: break-word;
}

.itemDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
}

.remove {
    font-size: var(--small-font);
}

@media (max-width: 800px) {
    .productContainer{
        width: 100%;
    }
    .list {
        min-width: unset;
        width: 100%;
    }
}
@media (max-width: 400px){
    .product {
        display: block;
    }

    .productTitle {
        text-align: left
    }

    .productContainer{
        text-align: left
    }

    .itemDetails {
        display: block;
    }
}
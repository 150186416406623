.container {
    display: inline-block;
    position: relative;
    font-size: 12px;
}

.container:hover .button, .container:hover .options{
    box-shadow: 0px 0px 2px 2px var(--primary);
}

.container:hover .options {
    display: block;
}

.button {
    padding: 3px 3px 3px 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-width: 1px;
}

.options {
    display: none;
    position: absolute;
    overflow: auto;
    background-color:white;
    width: 100%;
    border-radius:8px;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
}
  
.options a {
    display: block;
    color: black;
    padding: 10px 5px;
    text-decoration: none;
    background-color: white;
  }

  .options a:hover {
    filter:brightness(80%)
  }
.container {
    height: fit-content;
    margin-bottom: 1em;
}

.terms {
    margin-top: 1em;
}

.loginButton {
   margin-top: 0.5em
}
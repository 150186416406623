.dateSlider{
    padding-bottom: 2px;
    border-bottom: 1px outset;
    box-shadow:  0 4px 2px -2px var(--light-grey);
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topRight {
    margin: 0.5em;
}

.selection {
    text-align: left;
    padding: 0 1em;
    margin: 0.5em 0;
}

.selection > span {
    font-weight: 600;
    border: 1px outset transparent;
    padding: 5px;
    border-radius: 8px;
    user-select: none;
}

.selection > span.date {
    border-color: var(--light-grey);
    background-color: var(--primary);
    cursor: pointer;
}

.selection > span.date:hover {
    background-color: var(--light-primary);
}

.selection > span.date:active {
    border: 1px solid var(--primary);
}
.container {
    width: 100%;
}

.month {
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
}
.week {
    display: flex;
}
/* Day styles */
.dayContainer, .arrow {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
}

.dayNumber {
    font-size: 1.1em;
    font-weight: 600;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.dayNumber:hover, .arrow:hover {
    border-color:  var(--light-primary);
    background-color: var(--light-primary);
}
.dayContainer.selected > .dayNumber, .dayContainer.selected.active > .dayNumber{
    border-color:  var(--primary);
    background-color: var(--primary);
}
.dayContainer.selected.active > .dayNumber {
    box-shadow: 0px 0px 2px 5px var(--light-primary);
}

.dayContainer.active > .dayNumber {
    border-color:  var(--primary);
    background-color: var(--light-primary);
}

.dayContainer.disabled > .dayNumber, 
.dayContainer.disabled > .dayNumber:hover,
.arrow.disabled,
.arrow.disabled:hover
 {
    border-color: transparent;
    background-color: transparent;
    color: var(--disabled-grey);
    cursor: default;
}

.dayOfWeek {
    font-size: 0.8em;
}
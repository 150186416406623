.container {
    padding: 2%;
    flex-grow: 1;
    flex-basis: 16%;
    text-align: center;
    cursor: pointer;
    margin: 10px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 25%;
    min-width: 300px;
    min-height: 400px;
    max-height: 500px;
    background: var(--white);
    overflow: hidden;
    box-shadow: 5px 5px 3px var(--light-grey);
    position: relative;
}

.container:hover {
    border: 3px solid var(--light-primary);
    filter: brightness(90%);
    box-shadow: none
}

.productPrice {
    font-weight: 600;
    font-size: var(--strong-font);
}

.sellerName {
    font-size: var(--small-font);
    color: var(--grey)
}

.productInfo button{
    width: 100%;
    margin-top: 5px;
}

.productTitle {
    max-height: 45px;
    overflow: auto;
}
.productInfo {
    padding: 5px
}

.container img{
    max-width: 100%;
}

.sale .productPrice{
    color: var(--sale-red)
}

.saleTag {
    background-color: var(--sale-red);
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    border: 1px dashed white;
    right: 15px;
    top: 10px;
    font-size: 1.6em;
    font-family: 'Raleway';
}

.saleOff{
    font-size: var(--tiny-font);
    display: block;
}

.onlyVisible {
    position: absolute;
    background-color: #2873d0bf;
    color: var(--white);
    top: 30%;
    left: 15px;
    font-size: var(--small-font);
    border-radius: 16px;
    max-width: 250px;
    padding: 5px;
}

.variantPickerContainer {
    max-height: 90px;
    overflow-y: auto;
}
@media(min-width:1300px){
    .container {
        min-width: 22%
    }
}
@media(max-width:1200px) {
    .container {
        min-width: 220px;
        max-width: 33%;
    }
}
@media(max-width: 1000px){
    .container {
        min-width: 200px;
        max-width: 45%;
    }
}
@media(max-width: 700px){
    .container {
        border: 1px solid var(--light-grey);
        border-collapse: collapse;
        margin: 3px;
        display: flex;
        min-height: 25vh;
        max-height: 30vh;
        flex-direction: row;
        max-width: 100%;
        min-width: 100%;
        /* box-shadow: none; */
    }

    .container.mobilePortrait {
        flex-direction: column;
        max-width: 40%;
        min-width: 45%;
        max-height: 40vh;
    }
    .container.mobilePortrait .productTitle {
        font-size: var(--small-font)
    }

    .container.mobilePortrait .productPrice {
        font-size: 0.9em;
    }

    .container > div {
        flex: 1;
    }

    .container > .productInfo {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .container img {
        max-width: 40vw;
        max-height: 100%; 
    }

    .container.mobilePortrait img {
        max-width: 100%;
        max-height: 14vh;
    } 

}

@media(max-width: 500px){
    .saleTag {
        width: 45px;
        height: 45px;
        font-size: 1.2em;
        right: 2px;
    }

    .variantPickerContainer {
        max-height: 70px;
        min-height: 35px;
        max-width: 200px;
        overflow: auto;
    }
}
@media(max-height: 400px){
    .container.mobilePortrait {
        max-height: 60vh;
        min-height: 200px;
    }

    .container.mobilePortrait img {
        max-height: 20vh;
        min-height: 50px;
    } 
}
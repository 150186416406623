.minus {
    color: var(--dark-primary)
}

.plus {
    color: var(--sale-red)
}

.value {
    white-space: nowrap;
}
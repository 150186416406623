.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    background: var(--light-grey)
}

.teaser {
    display: flex;
    align-items: center
}
.container img {
    height: 18vh;
    width: auto;
}
.mainContent {
    flex-direction: column;
    padding-left: 1em;
    padding-right: 1em;
}

.innerContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
} 

.invoiceTop {
    width: 100%;
    margin-bottom: 1em;
    display: none;
}

.contactInfo {
    text-align: center;
    padding: 1em;
    font-size: var(--small-font);

}



@media (max-width: 1270px){
    .innerContainer{
        /* flex-direction: column; */
        display: block
    }
}

@media (max-width: 1270px){
    .invoiceTop{
        display: block
    }
}
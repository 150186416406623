.mainContent {
    flex-direction: column;
    align-items: center;
    background: var(--very-light-grey);
    padding-left: 2em;
    padding-right: 2em;
}

.linkContainer {
    width: 100%
}

.contactForm {
    margin: 1em;
}

@media(max-width: 700px){
    .mainContent{
        padding-left: 1em;
        padding-right: 1em;
    }
}
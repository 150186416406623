.container {
    background: #fff9f9ff;
    background-image: url("/heart-confetti.png");
    display: flex;
    /* padding: 1em; */
    justify-content: space-between;
    margin-bottom: 1em;
    max-width: 100%;
} 

.visual {
    border: none;
    /* width:420px; */
    height: 315px;
}

.visual img {
    height: 100%;
    width: auto
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    justify-content: space-between;
}

.title {
    color: #ac1a3b;
    font-size: 3em;
    font-family: DancingScript;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1em 0;
}

.button {
    border: 1px solid #ac1a3b;
    /* background: #000; */
    font-weight: 700;
    color: #ac1a3b;
    padding: 0.5em 1em;
    font-size: 1.1em;
    width: 100%;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 0px 20px var(--very-light-grey);
}

.button, button:visited, .button:active {
    text-decoration: none
}

@media(max-width: 1200px){
    .title {
        font-size: 2.2em;
    }
}
@media(max-width: 1000px){
    .container {
        height: 200px;
        max-height: 200px
    }
    .content {
        padding: 1em;
        justify-content: space-between;
    }
    .title {
        font-size: 1.8em;
    }
    .button {
        font-size: var(--small-font)
    }
    .visual{
        /* width: 100%; */
        height: 100%;
        max-height: 200px;
    }
}

@media(max-width: 750px){
    .visual{
        max-height: 150px
    }
    .container {
        max-height: 150px
    }
    .title {
        font-size: 1.5em
    }
    .button {
        font-size: 0.6em
    }
}

@media(max-width: 500px){
    .title{
        font-size: 1.1em;
        padding: 0;
    }

    .content {
        padding: 0.5em
    }
    .button {
        margin-top :5px
    }
}

@media(max-width: 330px){

    .content {
        padding: 0
    }
    .button {
        font-size: 0.6em;
    }
}

@media(max-width: 300px){
    .title {
        font-size: 1em
    }
    .button {
        font-size: 0.4em;
    }
}
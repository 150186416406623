.container {
    display: flex;
    justify-content: center;
}

.innerContainer {
    display: flex;
    flex-direction: column;
}

.cardContainer {
    display: flex;
    justify-content: center;
}
.explanation {
    margin: 1em;
    text-align: center;
}

@media(max-width: 700px){
    .innerContainer {
        width: 100%;
        padding: 1em
    }
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
}

.container img {
    height: 30vh;
    width: auto;
}
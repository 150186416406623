.container {
    padding: 0.5em 1em;
    margin: 0.5em;
    display: flex;
    background: var(--white);
    align-items: center;
    width: fit-content;
    border-radius: 20em;
    border: 1px outset;
    text-decoration: none;
    cursor: pointer;
}

.container:hover {
    filter:brightness(90%);
}
@media(max-width: 700px){
    .container {
        padding: 0.5em
    }
}
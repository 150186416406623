.container {
    width: fit-content;
    padding: 2em;
    background: var(--white);
    border: 1px outset;
    border-radius: 16px;
    margin-bottom: 2em;
    max-width: 100vw;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.bottom {
    margin-top: 1em;
    display: flex;
    justify-content: right;
}
.title {
    margin-top: 0;
    display: flex;
    align-items: center;
}

.title > img {
    height: 60px;
    margin-right: 1em;
}
.innerContainer {
    width: 100%;
    display: flex;
}

.checkoutPanel{
    width: 250px;
    background: var(--very-light-grey);
    height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-width: 185px;
    border-radius: 16px;
    border: 1px outset;
    margin-left: 1em;
}

.list {
    flex: 1;
}

.charList {
    padding: 0.5em 1em;
    background-color: var(--very-light-grey);
    width: fit-content;
    font-size: var(--med-font);    
    border-radius: 5px;
}

.charList span {
    font-weight: 600;
}
@media (max-width: 1100px){
    .innerContainer{
        flex-direction: column;
        flex-flow: column-reverse;
    }

    .checkoutPanel{
        width: 100%;
        /* margin-left: unset; */
        margin-bottom: 1em;
    }
    
}

@media (max-width: 700px){
    .top {
        flex-direction: column;
    }
}


.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1em
}



.specifics {
    max-width: 30vw;
    margin-top: 0.5em
}

.specifics > li {
    margin-bottom: 1em;
}





@media(max-width: 700px){


    .specifics{
        max-width: none;
    }

    .specifics > li {
        margin-bottom: 0.5em
    }

    .specifics {
        font-size: var(--small-font);
        margin: 0.5em 0;
    }
}
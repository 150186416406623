.headerContent {
    flex: 1;
    flex-wrap: wrap;
}
.headerContent, .headerContainer {
    display: flex;
    align-items: center;
}

.headerContainer, .headerContainer > button, .container > img{
    margin: 0 5px;
}

.container {
    max-width: 100vw
}

.greetingContainer, .phoneOnly {
    display: none
}

.cart {
    margin: 0 1em;
    flex: 1;
    justify-content: flex-end;
}

.greeting {
    color: var(--white);
    margin-left: 1em;
    text-transform: capitalize;
    border: 1px solid var(--white);
    border-radius: 30px;
    padding: 5px 8px;
}

.hiLogin {
    text-decoration: underline;
    color: var(--white);
    margin: 0 1em;
    text-transform: capitalize;
    cursor: pointer;
}
.webOnly .greeting, .greetingContainer .greeting{
    display: inline-flex;
    align-items: center;
    font-size: var(--small-font);
    margin-right: 1em;
    font-weight: 600
}

.greeting span {
    margin-left: 5px
}
.wantToSell {
    color: var(--white);
    padding: 0 1em;
    cursor: pointer;
}

.webOnly {
    display: flex;
    align-items: center
}

.wallet {
    margin-right: 10px;
}
@media (max-width: 900px){
    .cart {
        margin: 0
    }
    .headerContainer.left{
        flex: 1
    }
}
@media (max-width: 700px){
    .container{
        display: block
    }

    .container img {
        margin-left: 0
    }

    

    .phoneOnly {
        display: flex
    }
    .webOnly {
        display: none
    }

    .greetingContainer {
        display: flex;
        align-items: center
    }

    .smallTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0 0
    }

    .headerContainer.left{
        margin-bottom: 6px;
    }

    .headerContainer {
        width: 100%;
        padding-right: 10px
    }

    .headerContainer.right {
        display: flex;
        justify-content: space-between;
    }

    .container{
        top: 0;
        transition: top 0.5s;
        /* overflow: hidden; */
    }
    
    .container.hidden {
        top: calc(var(--searchBarHeight) * -1)
    }

  }



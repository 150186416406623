.container {
    height: 42px;
    overflow: hidden;
    width: 40vw;
    display: flex;
    border-radius: 40px;
    border: 1px outset var(--dark);
    min-width: 250px;
    background-color: white;
}

.container:active {
    border-width: 2px;
}
.container input {
    outline: none;
    font-size: 1.1em;
}
.field {
    flex: 1;
    height: 100%;
    padding-left: 1em;
    border: none;
}

.container svg {
    color: var(--dark);
}

.searchButton {
    background-color: var(--primary);
    height: 35px;
    width: 35px;
    margin: 2px 4px;
    border: 3px solid var(--primary);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchButton:active {
    border-style: inset;
    filter: brightness(80%);
}

@media (max-width: 1200px){
    .container{
        width: 30vw
    } 
}

@media (max-width: 900px){
    .container{
        width: 100%
    } 
}
.profileContainer {
    padding: 2em 4em;
    display: flex;
}

.about {
    flex: 1;
    display: flex;
    margin-left: 2em;
}

.description {
    font-size: var(--small-font);
    width: 300px;
    margin: 0 1em;
    text-align: justify;
}

.sellerInfo{
    /* display: flex; */
    justify-content: space-between;
} 

.logo {
    margin-top: calc((75px + 2em) * -1);
}

.name {
    font-weight: 600;
    text-transform: capitalize;
}

.followers {
    font-size: var(--small-font)
}

.buttonHolder {
    display: flex;
    margin-top: 1em
}

.buttonHolder button {
    margin-right: 5px
}

.goToProducts {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: var(--small-font);
}

@media(max-width: 700px){
    .logo {
        margin-top: -50px;
        display: flex;
        justify-content: center;
    }

    .profileContainer, .sellerInfo{
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .buttonHolder {
        text-align: center;
    }

    .about {
        flex-direction: column;
        margin: 1em 0 0 0;
        align-items: center;
    }

    .description {
        margin: 0.5em 0;
    }
}
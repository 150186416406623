.categoryTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex: 1; */
    width: 300px;
    margin: 10px;
    cursor: pointer;
}

.categoryTile:hover {
    transform: scale(1.2);
}

.categoryTileLabel {
    text-align: center;
    /* color: var(--grey); */
    font-weight: 600;
}
.container {
    height: 45px;
    width: 45px;
    min-width: 45px;
    border: 1px solid var(--light-grey); 
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--white);
}

.container.selected {
    border-width: 2px;
    border-color: var(--primary);
}

.thumbnailImage{
    max-height: 45px;
    max-width: 45px;
    user-select: none;
}

.container:hover {
    overflow: visible;
}
.expand .thumbnailImage:active {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    z-index: 202;
    position: absolute;
    bottom: 5px;
    left: 5px; 
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    background-color: white;
}
.prompt {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.prompt > span {
    margin-right: 0.5em
}
.prompt label {
    margin-top: -5px;
}

.addressValue {
    font-weight: 600;
}

.section {
    margin-left: 2em;
}

.userName {
    text-transform: capitalize;
}

.explanation {
    font-weight: 100;
    margin-bottom: 5px;
}
@media(max-width: 700px){
    .specifics{
        font-size: var(--small-font);
    }

    .explanation {
        color: var(--grey);
        font-size: 0.9em

    }
}
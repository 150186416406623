.container {
    margin-bottom: 5px;
    width: 100%;
}

.inputContainer {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 5px;
}

.right {
    margin-left: 5px
}

.label {
    text-transform: capitalize;
    margin-bottom: 5px;
    font-size: 14px;
}

.errorText {
    font-size: 14px;
    color: var(--error-red);
}
.errorText::first-letter {
    text-transform: capitalize
}

.error input, .error select, .error textarea {
    border-color: var(--error-red);
}

.input {
    width: 100%;
    position: relative;
}
.iconButton {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.container {
    padding-bottom: 10em;
    background: var(--green-grey);
    width: 85vw
}

.container :global(p.title){
    color: var(--secondary)
}
.items {
    display: flex;
}

.container :global(.title){
    padding: 0.1em 1em;
}
@media (max-width: 1000px){
    .container{
        width: 75vw
    }
}
@media(max-width: 700px){
    .container{
        padding: 0 0 10em 0;
        width: 100%;
    }
}